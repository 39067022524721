import React from 'react';
import { Link } from 'react-router-dom';

import { Hero } from '@/components';
import { useNavigate } from '@/hooks';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Hero className="text-center py-7">
      <h1>Sorry, that page or resource couldn't be found.</h1>
      <p className="mt-4">
        You can{' '}
        <a href="#" onClick={() => navigate.goBack()}>
          click here
        </a>{' '}
        to return to the previous page or <Link to="/">go home</Link>.
      </p>
    </Hero>
  );
}
