import { cookies } from '@/services';

export default async function httpRequest(url, method = 'GET', options = {}) {
  // Use json ContentType, Accept as default.
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (/post/i.test(method) || /put/i.test(method)) {
    const xsrfToken = cookies.get('XSRFToken');
    if (xsrfToken) {
      headers['X-XSRFToken'] = xsrfToken;
    }
  }

  let config = {
    method,
    headers: Object.assign(headers, options.headers),
    credentials: 'include',
  };

  // Support array or object payloads...
  const { payload } = options;
  if (payload) {
    if (/get/i.test(method)) {
      if (typeof payload !== 'object' || Object.keys(payload).length === 0) {
        throw new Error(
          'An HTTP request with method type GET must provide an object for the payload'
        );
      }

      // Append the payload to the query string.
      const prefix = url.indexOf('?') >= 0 ? '&' : '?';
      url += Object.entries(payload).reduce((acc, [key, value], i) => {
        return `${acc}${i === 0 ? prefix : '&'}${encodeURI(key)}=${encodeURI(
          value == null
            ? ''
            : typeof value === 'object'
            ? JSON.stringify(value)
            : value?.toString().replace(/[^\S\r\n]{2,}/g, '')
        )}`;
      }, '');
    } else if (/post/i.test(method)) {
      // Add the payload to the body.
      config.body = JSON.stringify(payload);
    }
  }

  const response = await fetch(url, config);

  // We only support parsing of JSON here, but other response
  // content types would be easy to support.
  let data = await response.text();
  const contentType = response.headers.get('Content-Type');

  if (
    contentType &&
    (contentType.startsWith('application/json') ||
      contentType.startsWith('application/problem+json'))
  ) {
    data = JSON.parse(data);
  }

  if (!response.ok) {
    let error = new Error(
      `The request failed with status code '${response.status}${
        response.statusText ? ' ' + response.statusText : ''
      }'`
    );
    error.response = response;
    error.status = response.status;
    error.isNotFound = response.status === 404;
    if (data) error.result = data;
    throw error;
  }

  return data;
}

httpRequest.get = (url, options) => httpRequest(url, 'GET', options);
httpRequest.post = (url, options) => httpRequest(url, 'POST', options);

export const get = httpRequest.get;
export const post = httpRequest.post;
