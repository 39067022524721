import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import range from 'lodash/range';

import { useMedia } from '@/hooks';

import styles from './Pager.module.scss';

export default function Pager({ pageNumber, totalPages, onChange, className }) {
  pageNumber = parseInt(pageNumber);
  totalPages = parseInt(totalPages);

  function handleDelta(increment) {
    if (
      (increment < 0 && pageNumber > 1) ||
      (increment > 0 && pageNumber < totalPages)
    ) {
      onChange(pageNumber + increment);
    }
  }

  function handleChange(num) {
    if (num > 0 && num <= totalPages) {
      onChange(num);
    }
  }

  const maxPageNumsToDisplay = useMedia(
    ['(min-width: 768px)', '(min-width: 576px)'],
    [12, 8],
    4
  );

  const pageNumsToDisplay = Math.min(totalPages, maxPageNumsToDisplay);

  let startPage = 1;
  let endPage = pageNumsToDisplay;

  if (totalPages === 1) {
    startPage = 1;
    endPage = 1;
  } else if (pageNumber > totalPages - pageNumsToDisplay + 1) {
    startPage = totalPages - pageNumsToDisplay + 1;
    endPage = totalPages;
  } else if (pageNumber >= pageNumsToDisplay) {
    startPage = pageNumber - 1;
    endPage = pageNumber + (pageNumsToDisplay - 2);
  }

  const showEllipsis = endPage < totalPages;
  const pageRange = range(startPage, endPage + 1);

  return (
    <div className={classNames(className, 'd-flex justify-content-center')}>
      <button
        onClick={() => handleDelta(-1)}
        disabled={pageNumber === 1}
        className={styles['pager-btn']}>
        <span className="icon-arrow-left"></span> Prev
      </button>
      {pageRange.map((num) => (
        <button
          key={num}
          onClick={() => handleChange(num)}
          className={classNames(styles['pager-num'], {
            circled: num === pageNumber,
          })}>
          {totalPages !== 1 && num === endPage && showEllipsis ? <>...</> : num}
        </button>
      ))}
      <button
        onClick={() => handleDelta(+1)}
        disabled={pageNumber === totalPages}
        className={styles['pager-btn']}>
        Next <span className="icon-arrow-right"></span>
      </button>
    </div>
  );
}

Pager.propTypes = {
  pageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
};
