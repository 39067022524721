import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CustomInput } from 'reactstrap';
import { ErrorMessage } from '@/components/form';

import './FileInput.scss';

export default function FileInput(props) {
  const [field, meta, helpers] = useField(props.name);
  const isError = meta.touched && meta.error != null;
  const { setValue } = helpers;

  const { value } = field;
  const placeholder =
    value != null && typeof value === 'object' && value.name != null
      ? value.name
      : props.placeholder;

  const handleChange = useCallback(
    (e) => {
      if (e.target.files.length === 0) {
        return setValue(null);
      }

      const file = e.target.files[0];

      const newValue = {
        name: file.name,
        size: file.size,
        type: file.type,
      };

      if (file.size <= props.maxFileSize) {
        const fileReader = new FileReader();
        fileReader.onload = function () {
          const dataURL = fileReader.result;
          newValue.base64Content = dataURL.substr(dataURL.indexOf(',') + 1);
        };
        fileReader.readAsDataURL(file);
      }

      setValue(newValue);
    },
    [setValue, props.maxFileSize]
  );

  return (
    <>
      <CustomInput
        type="file"
        id={props.name}
        accept={props.accept}
        label={placeholder}
        onChange={handleChange}
        onBlur={field.onBlur}
        invalid={isError}
      />
      <ErrorMessage name={props.name} alwaysShow />
    </>
  );
}

FileInput.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
};
