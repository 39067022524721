import * as Yup from 'yup';

/**
 * getValidationSchema
 * @param {Object} schema Gets the validation schema from the provided mixed schema.
 */
export default function getValidationSchema(schema) {
  if (schema == null) return {};

  // Schema is array
  if (Array.isArray(schema)) {
    return Yup.array().of(getValidationSchema(schema[0]));
  }

  // Schema is object
  let ret = {};
  for (const prop in schema) {
    if (schema[prop] != null) {
      // Schema - { foo: { default: 0, validation: ... }}
      if (typeof schema[prop] === 'object') {
        if (prop !== 'validation') {
          // Schema - { foo: { validation: ... }}
          if (schema[prop]['validation'] !== undefined) {
            ret[prop] = schema[prop]['validation'];

            // TODO: Only do this if the obj contains more than default/validation props
            if (ret[prop].type === 'object') {
              // Ret - { foo: [Yup.object].shape(...) }
              ret[prop] = getValidationSchema(schema[prop]).concat(ret[prop]);
            }
          }
          // Ret - { foo: [yup schema] } or {}
          else if (ret[prop] == null) {
            ret[prop] = getValidationSchema(schema[prop]);
          }
        }
      }
    }
  }

  return Yup.object(ret);
}
