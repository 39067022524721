import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { Loader } from '@/components';
import { httpRequest } from '@/services';
import { LoadingProvider } from '@/context';
import { useTitle, useLoad } from '@/hooks';

import VideoList from './components/VideoList';
import VideoPlayer from './components/VideoPlayer';
import BodyContainer from './components/BodyContainer';

import settings from '@/settings';
const { cmsSiteBaseUrl } = settings;

export default function Videos() {
  useTitle('Videos');

  const { slug } = useParams();
  const [posts, setPosts] = useState([]);

  const { status: loadingStatus } = useLoad(() =>
    httpRequest.get(`${cmsSiteBaseUrl}/api/video/posts`).then((posts) => {
      if (posts && posts.length > 0) {
        setPosts(posts);
      }
    })
  );

  let selectedPost = posts[0];
  if (slug && posts.length > 0) {
    selectedPost = posts.find((post) => post.slug === slug);
  }

  return (
    <LoadingProvider {...loadingStatus}>
      <Loader>
        <BodyContainer>
          <Row>
            <Col className="mt-lg-5">
              <Row className="h-100">
                <Col xs={18} lg={11} className="mb-4 mb-lg-0">
                  <VideoPlayer
                    className="mx-md-n4 mx-lg-0"
                    post={selectedPost}
                  />
                </Col>
                <Col xs={18} lg={7} className="ml-lg-n4">
                  <VideoList
                    className="mt-4 mt-lg-0"
                    posts={posts}
                    selectedPost={selectedPost}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}

Videos.propTypes = {
  slug: PropTypes.string,
};
