import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from '@/components';
import { Button as InnerButton } from 'reactstrap';

const defaultProps = {
  outline: false,
  disableOnLoading: true,
};

const Button = forwardRef((props, ref) => {
  props = Object.assign({}, defaultProps, props);

  const {
    tag,
    color,
    isLoading,
    loader,
    disabled,
    disableOnLoading,
    children,
    className,
    ...otherProps
  } = props;

  const isDisabled = disabled || (disableOnLoading && isLoading);

  let resolvedTag = tag || 'button';
  let resolvedColor = color;
  let buttonClasses = classNames(className);

  if (color === 'inline') {
    buttonClasses = classNames(buttonClasses, 'btn-inline');
    resolvedColor = 'link';
  }

  return (
    <InnerButton
      tag={resolvedTag}
      color={resolvedColor}
      disabled={isDisabled}
      className={buttonClasses}
      ref={ref}
      {...otherProps}>
      {isLoading ? loader || <Spinner size="sm" color="light" /> : children}
    </InnerButton>
  );
});

Button.propTypes = {
  isLoading: PropTypes.bool,
  loader: PropTypes.oneOfType([PropTypes.node]),
  disableOnLoading: PropTypes.bool,
};

export default Button;
