import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser from 'html-react-parser';
import showdown from 'showdown';

export default function Terms({ message }) {
  if (message != null) {
    const converter = new showdown.Converter();
    const termsAndConditions = converter.makeHtml(message);

    return (
      <div>
        <div>{ReactHtmlParser(termsAndConditions)}</div>
      </div>
    );
  } else {
    return <div>No terms and conditions are available.</div>;
  }
}

export function TermsModal({ showTerms, toggleShowTerms, message }) {
  return (
    <Modal size="xl" isOpen={showTerms} toggle={toggleShowTerms}>
      <ModalHeader toggle={toggleShowTerms}>Terms and Conditions</ModalHeader>
      <ModalBody>
        <Terms message={message} />
      </ModalBody>
    </Modal>
  );
}

TermsModal.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  toggleShowTerms: PropTypes.func,
  message: PropTypes.string,
};
