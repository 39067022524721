import * as Yup from 'yup';
import { getDefaultState, getValidationSchema } from '@/services/Schema';

export const intlPhonePattern = /^\+?[\d -]{9,15}$/gi;

const _Schema = {
  event: {
    locationID: {
      default: 0,
      validation: Yup.number()
        .moreThan(0, 'A location is required')
        .required('A location is required'),
    },
    eventID: {
      default: 0,
      validation: Yup.number()
        .moreThan(0, 'An event is required')
        .required('An event is required'),
    },
  },
  contact: {
    companyName: {
      default: '',
      validation: Yup.string().required('A company name is required'),
    },
    firstName: {
      default: '',
      validation: Yup.string().required('A first name is required'),
    },
    lastName: {
      default: '',
      validation: Yup.string().required('A last name is required'),
    },
    phone: {
      default: '',
      validation: Yup.string()
        .matches(intlPhonePattern, 'The phone number is not valid')
        .required('A phone number is required'),
    },
    email: {
      default: '',
      validation: Yup.string()
        .email('The email address is not valid')
        .required('An email address is required'),
    },
  },
  methodOfContact: {
    default: 'Phone',
    validation: Yup.string().required('A method of contact is required'),
  },
  message: {
    default: '',
    validation: Yup.string().required('A message is required'),
  },
};

export const Schema = {
  defaultState: getDefaultState(_Schema),
  validation: getValidationSchema(_Schema),
};
