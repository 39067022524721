/**
 * formatPhoneNumber
 * @param {String} string The phone number to format.
 * @param {String} formatType The style to format the phone number to (plain, parens, noparens, hyphens).
 * @summary Note that US and Canada phone numbers have the same formatting and country codes.
 */
export default function formatPhoneNumber(string, formatType = 'parens') {
  if (!string) return '';

  // Distill down to just the numbers.
  // We only format 10-digit numbers at this time.
  let numbers = string.match(/\d+/g)?.join('');
  if (numbers == null) return string;

  // We support a country code of +1 only.
  let countryCode = '';
  if (numbers.length === 11 && numbers.substring(0, 1) === '1') {
    countryCode = `+1`;
    numbers = numbers.substring(1);
  }

  if (numbers.length !== 10) {
    return string;
  }

  switch (formatType) {
    case 'plain': {
      return `${countryCode}${numbers}`;
    }
    case 'parens': {
      return `${countryCode ? countryCode + ' ' : ''}(${numbers.substring(
        0,
        3
      )}) ${numbers.substring(3, 6)}-${numbers.substring(6)}`;
    }
    case 'noparens': {
      return `${countryCode ? countryCode + ' ' : ''}${numbers.substring(0, 3)} ${numbers.substring(
        3,
        6
      )}-${numbers.substring(6)}`;
    }
    case 'hyphens': {
      return `${countryCode ? countryCode + ' ' : ''}${numbers.substring(0, 3)}-${numbers.substring(
        3,
        6
      )}-${numbers.substring(6)}`;
    }
    default: {
      console.warn(`format style type "${formatType}" is unrecognized`);
      return string;
    }
  }
}
