import PropTypes from 'prop-types';

const commonPropTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fullSize: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
  }),
  category: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  publishedOn: PropTypes.string,
};

export const postSummaryShape = PropTypes.shape({
  ...commonPropTypes,
});

export const postDetailsShape = PropTypes.shape({
  ...commonPropTypes,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      metadata: PropTypes.object,
    })
  ),
});

export const categoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});
