import { useRef, useLayoutEffect } from 'react';
import { useField } from 'formik';

/**
 * Fixes an issue in Formik where functions from setField don't maintain
 * their referential integrity, causing more reloads than necessary and
 * worse case, endless loops.
 * See https://github.com/formium/formik/issues/2268
 * @param {any} props The same props that would be passed into Formik's useField.
 * @returns An array comprised of field, meta, and helpers respectively.
 */
export default function useFormikFieldFast(props) {
  const [field, meta, helpers] = useField(props);

  const latestRef = useRef({});

  // On every render save newest helpers to latestRef
  latestRef.current.setError = helpers.setError;
  latestRef.current.setValue = helpers.setValue;
  latestRef.current.setTouched = helpers.setTouched;

  // On the first render create new function which will never change
  // but call newest helper function
  if (!latestRef.current.helpers) {
    latestRef.current.helpers = {
      setError: (...args) => latestRef.current.setError(...args),
      setValue: (...args) => latestRef.current.setValue(...args),
      setTouched: (...args) => latestRef.current.setTouched(...args),
    };
  }

  latestRef.current.onBlur = field.onBlur;
  latestRef.current.onChange = field.onChange;

  // Same as above... Memoize the onChange and onBlur events.
  if (!latestRef.current.field) {
    latestRef.current.field = {
      ...field,
      onBlur: (...args) => latestRef.current.onBlur(...args),
      onChange: (...args) => latestRef.current.onChange(...args),
    };
  }

  // Make sure our ref of value is always up-to-date.
  useLayoutEffect(() => {
    latestRef.current.field.value = field.value;
  }, [field.value]);

  return [latestRef.current.field, meta, latestRef.current.helpers];
}
