import * as Yup from 'yup';
import { getDefaultState, getValidationSchema } from '@/services/Schema';

const _SignatureSchema = {
  name: {
    default: '',
        validation: Yup.string()
            .max(100, 'Your signature must not be more than ${max} characters')
            .required('Your signature is required'),
  },
  acceptTerms: {
    default: false,
    validation: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions'
    ),
  },
};

export const signatureSchema = {
  defaultValues: getDefaultState(_SignatureSchema),
  validation: getValidationSchema(_SignatureSchema),
};
