// IE11 polyfills
// These must be the first lines in src/index.js!
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import * as immer from 'immer';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import App from './App';
import { ScrollToTop } from '@/components';

const environment = process.env.NODE_ENV;
const version = process.env.REACT_APP_VERSION;
console.log(`Customer Portal SPA v${version}`);

// Explicitly enable fallback support for immer on older browsers.
// See https://immerjs.github.io/immer/installation/
immer.enableES5();

// Initialize Sentry
Sentry.init({
  dsn: 'https://affdba136f184dc8bceba3e2d6e4788a@o491984.ingest.sentry.io/5558474',
  environment: environment,
  release: `customer-portal@${version}`,
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0, 
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,

  ignoreErrors: [
    // Ignore email link Microsoft Outlook crawler error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Object Not Found Matching Id',
    // Ignore pushState error (occurs when scheme is file://)
    "Failed to execute 'pushState' on 'History'",
  ],
  denyUrls: [
    // Ignore Google Analytics script errors
    /\/(gtm|ga|analytics)\.js/i,
    /\/gtag\/js/,
    // Google Adsense
    /pagead\/js/i,
  ],
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop />
    <App baseUrl={baseUrl} rootElement={rootElement} />
  </BrowserRouter>
);
