import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function AlternatingRows(props) {
  const { primaryClass, secondaryClass } = props;

  const children = React.Children.map(props.children, (child, index) => {
    const classes = classNames(
      props.className,
      index % 2 === 0 ? primaryClass : secondaryClass
    );
    return cloneElement(
      child,
      {
        className: classNames(classes, child.props.className),
      },
      child.props.children
    );
  });

  return <>{children}</>;
}

AlternatingRows.propTypes = {
  primaryClass: PropTypes.string,
  secondaryClass: PropTypes.string,
};
