import React, { useState } from 'react';
import classNames from 'classnames';
import { format } from '@/services';
import { Row, Col } from 'reactstrap';
import { useAppContext } from '@/context';
import { useTitle, useNavigate, useLoad } from '@/hooks';
import { httpRequest } from '@/services';

export default function Footer(props) {
  const [locations, setLocations] = useState([]);
  const [detailedLocations, setDetailedLocations] = useState([]);
  const {phoneNumber } = useAppContext();
  const [phoneNumberUpdated, setPhoneUpdated] = useState('');
  const {
    status: { isLoading, error },
  } = useLoad(() => {
    return httpRequest.get('api/locations').then((data) => {
      setLocations(data);
      fetchLocationDetails(data); 
    });
  });
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  const fetchLocationDetails = async (locationsData) => {
    const detailedList = [];
    for (const location of locationsData) {
      const settingsDetails = await httpRequest.get(`api/locationsettings/${location.locationID}`);
      if (settingsDetails.digitalSignageUrl.includes(subdomain)) {    
        setPhoneUpdated(settingsDetails.csrPhone);
      }
      
    }
    setDetailedLocations(detailedList);
  };
  if (isLoading) {
    return null;
  };
  return (
    <footer className={classNames(props.className, 'mt-6 mb-4')}>
      <Row className="text-center text-size-sm text-medium mb-1">
        <Col>
          {!subdomain.includes('events') && phoneNumberUpdated && (
            <p className="d-md-inline-block">
              <label>Phone:</label>{' '}
              <a href={`tel:${format.phoneNumber(phoneNumberUpdated, 'plain')}`}>
                {format.phoneNumber(phoneNumberUpdated)}
              </a>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="text-center text-size-sm">
          <p>
            &copy; {new Date().getFullYear()} &mdash; Cox Business
            <br className="d-md-none" /> Advanced Convention Services
          </p>
        </Col>
      </Row>
    </footer>
  );
}