import React from 'react';
import flatten from 'flat';
import PropTypes from 'prop-types';
import { useFormikContext, useField } from 'formik';
import { FormFeedback } from 'reactstrap';

export function ErrorMessage(props) {
  const { children, alwaysShow } = props;

  const [, /*<-field*/ meta] = useField(props.name);
  const isError = (alwaysShow || meta.touched) && meta.error != null;
  const childCount = React.Children.count(children);

  const error =
    isError && typeof meta.error === 'object'
      ? Object.values(meta.error)
      : meta.error;

  return (
    <>
      {isError && (
        <FormFeedback valid={false} className="d-block">
          {childCount > 0 ? children : error}
        </FormFeedback>
      )}
    </>
  );
}

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  alwaysShow: PropTypes.bool,
};

export function ErrorMessages({ alwaysShow = false }) {
  const { isValid, errors } = useFormikContext();
  const errorKeys = isValid ? [] : Object.keys(flatten(errors));

  return (
    <>
      {errorKeys.map((key) => (
        <ErrorMessage alwaysShow={alwaysShow} key={key} name={key} />
      ))}
    </>
  );
}

ErrorMessages.propTypes = {
  alwaysShow: PropTypes.bool,
};
