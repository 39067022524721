import React from 'react';
import PropTypes from 'prop-types';
import { BulletList } from 'react-content-loader';
import { Fade } from 'reactstrap';

export default function TableContentLoader(props) {
  let { show, width, height, children, ...otherProps } = props;

  width = width || 100;
  height = height || 10;

  if (show == null) {
    show =
      children == null || (React.Children.count(children) === 1 && !children);
  }

  return (
    <div {...otherProps}>{show ? <BulletList /> : <Fade>{children}</Fade>}</div>
  );
}

TableContentLoader.propTypes = {
  show: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
