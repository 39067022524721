import React from 'react';
import { Spinner as InnerSpinner } from 'reactstrap';

export default function Spinner(props) {
  const { color: colorProp, size: sizeProp, otherProps } = props;
  const color = colorProp || 'primary';
  const size = sizeProp || '';

  return <InnerSpinner size={size} color={color} {...otherProps} />;
}
