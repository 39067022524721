import React, { useRef } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { utility } from '@/services';

const { getPublicFilePath } = utility;

export default function BoothLayoutLinks({ location }) {
  const { locationID } = location;
  const boothLayoutTipRef = useRef();

  // If LVCC, display the booth layout video.
  // If not, only display the form links.
  return locationID === 3 ? (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-link btn-inline"
        ref={boothLayoutTipRef}>
        What is a booth layout?
      </button>
      <UncontrolledPopover
        target={boothLayoutTipRef}
        trigger="legacy"
        placement="top">
        <PopoverHeader>What is a booth layout?</PopoverHeader>
        <PopoverBody>
          <p>
            Watch the video below for more information on what a booth layout is
            and what's required.
          </p>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              title="Booth diagram explained"
              src="https://www.youtube.com/embed/cBQQyCEDAfk?start=17&autoplay=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>

          <p className="mt-3">
            <FormDownloadLinks location={location} />
          </p>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
  ) : (
    <FormDownloadLinks location={location} />
  );
}

function FormDownloadLinks({ location }) {
  const forms = [
    {
      file: location.dataOrderFormFileName,
      name: 'Data Order Form',
    },
    {
      file: location.voiceVideoOrderFormFileName,
      name: 'Voice/Video Order Form',
    },
    {
      file: location.wifiHotspotOrderFormFileName,
      name: 'WiFi Hotspot Order Form',
    },
  ];

  const availableForms = forms.reduce((acc, form) => {
    if (form.file == null) return acc;
    const link = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={getPublicFilePath(form.file)}>
        {form.name}
      </a>
    );

    return acc === null ? (
      link
    ) : (
      <React.Fragment>
        {acc}, {link}
      </React.Fragment>
    );
  }, null);

  return availableForms != null ? (
    <React.Fragment>Download the {availableForms}</React.Fragment>
  ) : null;
}
