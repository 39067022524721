import React from 'react';
import { Row, Col } from 'reactstrap';

export default function BodyContainer({ className, children, ...rest }) {
  return (
    <Row className={className} {...rest}>
      <Col md={17} lg={15} className="mx-auto">
        {children}
      </Col>
    </Row>
  );
}
