import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Flow.module.scss';

export default function Flow(props) {
  const activeItem = props.items.find((item) => item.active);

  return (
    <div className={classNames(styles['flow-container'], props.className)}>
      {props.items.map((item, idx) => (
        <FlowItem
          key={idx}
          item={item}
          onClick={props.onClick}
          active={item === activeItem}
          completed={item.stepNumber < activeItem.stepNumber}
        />
      ))}
    </div>
  );
}

Flow.createFlowItem = function (stepNumber, caption, active) {
  return {
    stepNumber,
    caption,
    active,
  };
};

const flowItemShape = PropTypes.shape({
  stepNumber: PropTypes.number,
  caption: PropTypes.string,
  active: PropTypes.bool,
});

Flow.propTypes = {
  items: PropTypes.arrayOf(flowItemShape).isRequired,
  onClick: PropTypes.func,
};

function FlowItem(props) {
  function handleClick() {
    if (props.onClick) {
      props.onClick(props.item);
    }
  }

  let classes = [styles['flow-item']];
  if (props.active) classes.push(styles['active']);
  if (props.completed) classes.push(styles['completed']);

  const numberContent = props.completed ? (
    <i className="icon-check"></i>
  ) : (
    <span>{props.item.stepNumber}</span>
  );

  return (
    <div
      className={classNames(classes)}
      onClick={handleClick}
      title={props.item.caption}
      alt={props.item.caption}>
      <div className={styles['flow-item-num']}>{numberContent}</div>
      <div className={styles['flow-item-caption']}>{props.item.caption}</div>
    </div>
  );
}

FlowItem.propTypes = {
  item: flowItemShape.isRequired,
  completed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
