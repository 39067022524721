import React from 'react';
import Hero from './Hero';

export default function Error({ children }) {
  return (
    <Hero className="text-center py-7">
      {children || (
        <>
          <h1>Uh oh, something went wrong.</h1>
          <h3>Don't worry, this appears to be our fault.</h3>
          <p className="mt-4">
            Please note that we have been made aware of the issue and are
            working to correct it.
          </p>
        </>
      )}
    </Hero>
  );
}
