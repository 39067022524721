import { useState, useEffect } from 'react';

/**
 * Debounces the given state.
 * @param {any} value The state to debounce.
 * @param {Number} delay The amount of time in milliseconds to elapse before setting the debounced value.
 */
export default function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (delay === 0) {
      setDebouncedValue(value);
      return;
    }

    const handle = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handle);
    };
  }, [value, delay]);

  return debouncedValue;
}
