import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useNavigate } from '@/hooks';
import { postSummaryShape } from '../models';
import { Hero, SkeletonLoader } from '@/components';
import { useLoadingContext } from '@/context';
import { Row, Col } from 'reactstrap';

import styles from './FeaturedPost.module.scss';

export default function FeaturedPost({ post, className }) {
  const { getBlogUrl, getBlogPostUrl } = useNavigate();

  const { isLoading } = useLoadingContext();
  if (!isLoading && post == null) {
    return null;
  }

  return (
    <Hero
      className={classNames(className, styles['hero'])}
      style={{ backgroundImage: `url(${post?.image?.fullSize})` }}>
      {/* Container to hide any overflow */}
      <div className={styles['copy-container']}>
        {/* Container for the blue 'blob' background */}
        <Col
          xs="16"
          lg="10"
          xl="9"
          className={classNames(
            styles['copy'],
            'px-sm-5 pl-md-5 pr-md-4 pl-lg-6 pr-lg-5'
          )}>
          <Row className="h-100">
            <Col xs={{ offset: 1 }} lg={{ offset: 3 }} xl={{ offset: 6 }}>
              {/* Flexbox to vertically align the children */}
              <div className="h-100 d-flex flex-column justify-content-center">
                <h5 className="text-size-bold text-md-left">
                  <SkeletonLoader>
                    {() =>
                      post && (
                        <Link
                          className="text-decoration-none color-white"
                          to={getBlogUrl({ category: post.category })}>
                          {post.category}
                        </Link>
                      )
                    }
                  </SkeletonLoader>
                </h5>
                <h1 className="text-size-xxl text-md-left text-medium">
                  <SkeletonLoader width="75%">
                    {() => (
                      <Link
                        className="text-decoration-none color-white"
                        to={getBlogPostUrl(post.slug)}>
                        {post.title}
                      </Link>
                    )}
                  </SkeletonLoader>
                </h1>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </Hero>
  );
}

FeaturedPost.propTypes = {
  post: postSummaryShape,
  isLoading: PropTypes.bool,
};
