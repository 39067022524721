import React from 'react';

import { Table } from 'reactstrap';
import { useMedia } from '@/hooks';
import { format } from '@/services';
import { FlexTable, TableContentLoader } from '@/components';

export default function TransactionsList({
  transactions,
  isLoading,
  ...otherProps
}) {
  const isCollapsed = useMedia(
    ['(min-width: 960px)', 'print'],
    [false, false],
    true
  );

  return (
    <TableContentLoader show={isLoading} {...otherProps}>
      {transactions && transactions.length === 0 ? (
        <div>No transactions currently exist on this order.</div>
      ) : isCollapsed ? (
        <CollapsedTable className="mx-n3 mt-n3" transactions={transactions} />
      ) : (
        <RegularTable transactions={transactions} />
      )}
    </TableContentLoader>
  );
}

function CollapsedTable({ transactions, ...otherProps }) {
  return (
    <FlexTable striped secondaryClass="bg-light" {...otherProps}>
      <FlexTable.Head>
        <FlexTable.Cell>Reference ID</FlexTable.Cell>
        <FlexTable.Cell>Amount</FlexTable.Cell>
      </FlexTable.Head>
      <FlexTable.Body>
        {transactions.map((trans) => (
          <FlexTable.Row key={trans.referenceID}>
            <FlexTable.Cell noHeader className="mb-2">
              <div className="text-size-md text-bold">
                {new Date(trans.dateTime).toLocaleString()}
              </div>
            </FlexTable.Cell>
            <FlexTable.Cell title={trans.referenceID}>
              {trans.referenceID ?? '—'}
            </FlexTable.Cell>
            <FlexTable.Cell>{format.currency(trans.amount)}</FlexTable.Cell>
          </FlexTable.Row>
        ))}
      </FlexTable.Body>
    </FlexTable>
  );
}

function RegularTable({ transactions, ...otherProps }) {
  return (
    <Table size="sm" responsive {...otherProps}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Reference ID</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((trans) => (
          <tr key={trans.referenceID}>
            <td>
              <span className="text-size-md text-bold">
                {new Date(trans.dateTime).toLocaleString()}
              </span>
            </td>
            <td title={trans.referenceID}>{trans.referenceID ?? '—'}</td>
            <td>{format.currency(trans.amount)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
