import React, { useEffect, createElement } from 'react';
import { createPortal } from 'react-dom';
import ReactIs from 'react-is';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Fade } from 'reactstrap';
import { scrollToTop as _scrollToTop } from '@/services';
import { useLoadingContext } from '@/context/LoadingContext';
import { Spinner, Error, ErrorBoundary, NotFound } from '@/components';

const getDefaultFallback = (error) => {
  if (error?.status) {
    if (error.status.toString() === '404') {
      return NotFound;
    }
  }
  return Error;
};

export default function Loader({
  isLoading,
  useBackdrop = true,
  scrollToTop = true,
  fallback,
  error,
  children,
}) {
  const loadingContext = useLoadingContext();

  // If isLoading isn't provided through props,
  // check the loading context for a value.
  if (isLoading == null && loadingContext) {
    isLoading = loadingContext.isLoading;
    error = loadingContext.error;
  }

  // Hide overflow on the Y-axis so that the user
  // isn't able to scroll up or down.
  useEffect(() => {
    if (useBackdrop) {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflowY = isLoading ? 'hidden' : 'visible';
      if (scrollToTop && isLoading) _scrollToTop();
    }
  }, [isLoading, useBackdrop, scrollToTop]);

  if (error) {
    // If fallback is a non-react object, handle a special case
    // where an object with a 'status' key is provided.
    if (!ReactIs.isValidElementType(fallback) && typeof fallback === 'object') {
      if (error.status && fallback.status && fallback.status[error.status]) {
        const statusComponent = fallback.status[error.status];
        if (ReactIs.isValidElementType(statusComponent)) {
          return React.createElement(statusComponent);
        }
      }
    }

    // Support fallback render functions.
    if (typeof fallback === 'function') {
      return fallback(error);
    }

    // If a valid fallback isn't provided, use the default.
    if (!ReactIs.isValidElementType(fallback)) {
      return createElement(getDefaultFallback(error));
    }
  }

  // Create the backdrop at the app root.
  let backdrop = null;
  if (useBackdrop) {
    backdrop = createPortal(
      <Fade
        in={isLoading}
        className={classNames('modal-backdrop fade', { isLoading })}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner />
        </div>
      </Fade>,
      /*container*/
      document.getElementById('root')
    );
  }

  if (typeof children === 'function') {
    children = children();
  }

  return (
    <ErrorBoundary>
      {isLoading ? backdrop : null}
      {children}
    </ErrorBoundary>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  useBackdrop: PropTypes.bool,
  fallback: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({
      status: PropTypes.object,
    }),
  ]),
  error: PropTypes.object,
};
