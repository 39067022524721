import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@/components/form';
import { usePlaceOrderContext } from '../context';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import {
  default as SelectInput,
  makePair,
} from '@/components/form/SelectInput';

export default function AddressForm({
  namePrefix,
  stateOptions,
  countryOptions,
}) {
  const { setFieldValue } = usePlaceOrderContext();

  const buildName = (namePath) => `${namePrefix}.${namePath}`;

  function handleCountryChange() {
    setFieldValue(buildName('stateCode'), undefined);
  }

  return (
    <>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label>Address 1</Label>
            <TextInput name={buildName('address1')} placeholder="Address"  />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Address 2</Label> (Optional)
            <TextInput name={buildName('address2')} placeholder="Apt/Suite" />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={5}>
          <FormGroup>
            <Label>Postal Code</Label>
            <TextInput
              name={buildName('postalCode')}
              placeholder="Postal Code"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>City</Label>
            <TextInput name={buildName('city')} placeholder="City" />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={7}>
          <FormGroup>
            <Label>State/Province</Label>
            <SelectInput
              name={buildName('stateCode')}
              placeholder="Select a State/Province"
              options={stateOptions}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Country</Label>
            <SelectInput
              name={buildName('countryCode')}
              placeholder="Select a Country"
              options={countryOptions}
              onChange={handleCountryChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export const makeCountry = makePair;
export const makeState = (stateID, stateName, countryCode) => ({
  key: stateID,
  text: stateName,
  country: countryCode,
});

const stringOrNumberPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

AddressForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: stringOrNumberPropType,
      text: stringOrNumberPropType,
    })
  ),
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: stringOrNumberPropType,
      text: stringOrNumberPropType,
    })
  ).isRequired,
};
