import React from 'react';
import classNames from 'classnames';

import './Brand.scss';
import brandLogo from '../assets/images/cbhn-brand.svg';

export default function Brand({ showName = false, className }) {
  return (
    <>
      <img
        className={classNames(className, 'brand-logo')}
        src={brandLogo}
        alt="Cox Business/Hospitality Network"
        title="Cox Business/Hospitality Network"
      />
      {showName && <>Cox Business/Hospitality Network</>}
    </>
  );
}
