import { useState, useEffect, useCallback, useRef } from 'react';

import { useDebounce } from '@/hooks';

const optionDefaults = {
  /**
   * Whether the load should be performed upon calling the hook.
   */
  autoLoad: true,
  /**
   * The delay to apply to the debounce.
   */
  debounceDelay: 500,
  /**
   * Whether to display an overlay
   */
  showOverlay: true,
};

/**
 useLoad
 Sets up the state and effects necessary in order to display a loading state.
 @param action {Function} The function that performs the load.
 @param optionsOrDependencies {Object|Array|Boolean} False to disable auto-fetching, an options object, or array of dependencies.
 */
export default function useLoad(action, optionsOrDependencies) {
  if (typeof action !== 'function') {
    throw new TypeError('The provided action must be a function');
  }

  let options = Object.assign({}, optionDefaults);
  let dependencies = [];

  if (optionsOrDependencies != null) {
    if (Array.isArray(optionsOrDependencies)) {
      dependencies = optionsOrDependencies;
    } else if (typeof optionsOrDependencies === 'object') {
      options = Object.assign(options, optionsOrDependencies);
    }
  }

  const { autoLoad, debounceDelay } = options;
  let effectiveDebounceDelay = !!autoLoad ? 0 : debounceDelay;

  // Memoize the action to prevent re-renders and make
  // sure to re-render when any passed dependency updates.
  const actionCallback = useCallback(action, [...dependencies]);

  // Set up the loading and error states.
  const [error, setError] = useState();
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(autoLoad);
  const debouncedIsLoading = useDebounce(isLoading, effectiveDebounceDelay);

  // This state is only used internally to invoke the action.
  // It acts as a means to 'trigger' the effect below.
  const [doLoad, setDoLoad] = useState(false);
  const loadArgs = useRef();
  const loadCount = useRef(0);

  // Invoke the action.
  useEffect(() => {
    loadCount.current = loadCount.current + 1;
    if (loadCount.current === 1 && !autoLoad) {
      return;
    }

    setIsLoading(true);
    const result = actionCallback(loadArgs.current);
    if (result instanceof Promise) {
      result
        .then(() => {
          setIsLoaded(true);
        })
        .catch((error) => {
          setError(error);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
          loadArgs.current = undefined;
        });
    } else {
      setIsLoading(false);
    }
  }, [doLoad, actionCallback, autoLoad]);

  const prepareLoad = useCallback(
    (args) => {
      setDoLoad((val) => !val);
      loadArgs.current = args;
    },
    [setDoLoad]
  );

  /*TODO: Return an object like this instead: {
    isLoading,
    error,
    action: {
      loadCount,
      load
    }
  }*/

  return {
    status: {
      isLoaded: isLoaded,
      isLoading: debouncedIsLoading,
      isError: isError,
      error: error,
    },
    load: prepareLoad,
    loadCount: loadCount.current,
  };
}
