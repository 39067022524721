import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useNavigate } from '@/hooks';
import { Row, Col, Fade } from 'reactstrap';
import { SkeletonLoader } from '@/components';
import { videoPostShape } from '../models';

import styles from './VideoList.module.scss';

export default function VideoList({ posts, selectedPost, className }) {
  const navigate = useNavigate();

  const isSelected = (post) => selectedPost === post;

  return (
    <div className={classNames(styles['container'], className)}>
      {posts.map((post, idx) => (
        <React.Fragment key={idx}>
          <Row className="mb-4">
            <Col xs={9} lg={8}>
              <SkeletonLoader type="image" height={80}>
                {() => (
                  <Link
                    className="color-primary text-medium text-decoration-none"
                    to={navigate.getVideoPostUrl(post.slug)}>
                    <img
                      className={classNames('w-100', {
                        'border border-width-2 border-secondary border-transition scale-sm scale-transition':
                          isSelected(post),
                      })}
                      src={post.previewImage.preview}
                      title={post.title}
                      alt={post.title}
                    />
                  </Link>
                )}
              </SkeletonLoader>
            </Col>
            <Col
              xs={9}
              className="d-flex flex-column justify-content-center px-lg-0">
              {isSelected(post) && (
                <Fade
                  className="color-info text-medium text-size-sm"
                  in={isSelected(post)}>
                  <span className="">Now Playing</span>
                </Fade>
              )}
              <SkeletonLoader tag="div">
                {() => (
                  <Link
                    className="color-primary text-medium text-decoration-none text-lg-size-lg"
                    to={navigate.getVideoPostUrl(post.slug)}>
                    {post.title}
                  </Link>
                )}
              </SkeletonLoader>
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </div>
  );
}

VideoList.propTypes = {
  posts: PropTypes.arrayOf(videoPostShape).isRequired,
  selectedPost: videoPostShape,
};
