import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Fade } from 'reactstrap';
import styles from './Alert.module.scss';

export default function Message({
  type = 'info',
  vertical = false,
  children,
  className,
  iconClass,
  messageClass,
  items,
}) {
  const containerClasses = classNames(className, styles.container, {
    [styles.vertical]: vertical === true,
    [styles.warning]: type === 'warning',
    [styles.info]: type === 'info',
  });

  const iconClasses = classNames(iconClass, styles.icon);
  const messageClasses = classNames(messageClass, styles.message);

  return (
    <Fade tag="div" className={containerClasses}>
      {type === 'info' && (
        <svg
          className={iconClasses}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
      {type === 'warning' && (
        <svg
          className={iconClasses}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      )}
      {items ? (
        <div className={messageClasses}>
          <ul>
            {items.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={messageClasses}>{children}</div>
      )}
    </Fade>
  );
}

Message.propTypes = {
  type: PropTypes.string,
  vertical: PropTypes.bool,
  iconClass: PropTypes.string,
  messageClass: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};
