import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import { Row, Col } from 'reactstrap';
import { SkeletonLoader } from '@/components';
import { videoPostShape } from '../models';

import styles from './VideoPlayer.module.scss';

export default function VideoPlayer({ post, className }) {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setIsPlaying(false);
  }, [post]);

  function handlePlay() {
    setIsPlaying(true);
  }

  return (
    <div className={classNames('px-0 px-lg-4', className)}>
      <Row>
        <Col className={classNames(styles['container'], 'px-0 px-lg-3')}>
          <SkeletonLoader type="image-fill" height="300px">
            {() =>
              !isPlaying ? (
                <>
                  <img
                    className="w-100"
                    src={post.previewImage.fullSize}
                    title={post.title}
                    alt={post.title}
                  />
                  <div
                    style={{ top: 0 }}
                    className="position-absolute d-flex flex-column justify-content-center text-center text-size-lg w-100 h-100">
                    <button
                      className="btn-plain text-size-huge h-100"
                      alt={`Play '${post.title}'`}
                      title={`Play '${post.title}'`}
                      onClick={handlePlay}>
                      <i className="icon-play color-white" />
                    </button>
                  </div>
                </>
              ) : (
                <ReactPlayer
                  url={post.source}
                  height="40vh"
                  width="100%"
                  playing={true}
                  config={{
                    youtube: { playerVars: { modestbranding: 1 } },
                  }}
                />
              )
            }
          </SkeletonLoader>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 px-3">
          <SkeletonLoader width="100%">
            {() => (
              <h1 className="text-medium text-size-xxl text-lg-size-xxxl">
                {post.title}
              </h1>
            )}
          </SkeletonLoader>
          <SkeletonLoader>{() => post.summary}</SkeletonLoader>
        </Col>
      </Row>
    </div>
  );
}

VideoPlayer.propTypes = {
  selectedPost: videoPostShape,
};
