import React, { cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AlternatingRows } from '@/components';
import styles from './FlexTable.module.scss';

export default function FlexTable(props) {
  const containerRef = useRef();
  const { className, style, children: _, ...otherProps } = props;

  // Get head and body components.
  const children = React.Children.toArray(props.children);
  const head = children.find((child) => child.type === Head);
  const body = children.find((child) => child.type === Body);

  return (
    <div
      ref={containerRef}
      style={style}
      className={classNames(styles.table, className)}>
      <CollapsedTable head={head} body={body} {...otherProps} />
      {/* <RegularTable head={head} body={body} {...otherProps} /> */}
    </div>
  );
}

FlexTable.propTypes = {
  striped: PropTypes.bool,
  children: PropTypes.node,
  primaryClass: PropTypes.string,
  secondaryClass: PropTypes.string,
};

/**
 * CollapsedTable
 * @summary Returns a mobile version of the table for sub-medium screen sizes.
 */
function CollapsedTable(props) {
  const { head, body, primaryClass, secondaryClass } = props;

  function makeHeadCell(originalCell) {
    return cloneElement(originalCell, {
      className: classNames(
        originalCell.props.className,
        styles['header-collapsed']
      ),
    });
  }

  function makeValueCell(originalCell, noHeader) {
    return cloneElement(originalCell, {
      ...originalCell.props,
      className: classNames(originalCell.props.className, styles['cell'], {
        [styles['cell-value']]: !noHeader,
      }),
    });
  }

  function makeRow(originalRow, children) {
    return cloneElement(
      originalRow,
      {
        ...originalRow.props,
        className: styles['row-container'],
      },
      children
    );
  }

  let headCells = React.Children.toArray(head?.props.children).filter(
    (child) => child.type === Cell
  );

  let adjustedIndex;

  return (
    <AlternatingRows
      primaryClass={primaryClass}
      secondaryClass={secondaryClass}>
      {React.Children.map(body?.props.children, (rowChild) => {
        adjustedIndex = 0;
        return makeRow(
          rowChild, // Row... (flex container for row)
          React.Children.map(
            rowChild.props.children,
            (bodyCellChild, bodyCellChildIndex) => {
              const noHeader =
                bodyCellChild &&
                bodyCellChild.props &&
                bodyCellChild.props.noHeader;
              if (noHeader) adjustedIndex--;
              return (
                <Row horizontal>
                  {' '}
                  {/* Row (flex container for header and cell) */}
                  {!noHeader
                    ? makeHeadCell(
                        headCells[bodyCellChildIndex + adjustedIndex]
                      )
                    : null}
                  {makeValueCell(bodyCellChild, noHeader)}
                </Row>
              );
            }
          )
        );
      })}
    </AlternatingRows>
  );
}

/**
 * RegularTable
 * @summary Returns a desktop version of the table for medium screen sizes and higher.
 */
// function RegularTable({ head, body, ...props }) {
//   return (
//     <>
//       <div>{body}</div>
//       <Table {...props}></Table>
//     </>
//   );
// }

//
// Cell
//

function Cell({ className, noHeader, children, ...otherProps }) {
  return (
    <div className={classNames(styles.cell, className)} {...otherProps}>
      {children}
    </div>
  );
}

FlexTable.Cell = Cell;

//
// Head
//

function Head({ renderChildAtIndex, children }) {
  if (renderChildAtIndex != null) {
    children = React.Children.toArray().indexOf(renderChildAtIndex);
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        cloneElement(child, {
          className: classNames(child.className, styles.header),
        });
      })}
    </>
  );
}

FlexTable.Head = Head;

Head.propTypes = {
  children: PropTypes.arrayOf(Cell),
  renderChildAtIndex: PropTypes.number,
};

//
// Body
//

function Body() {
  return null;
}

FlexTable.Body = Body;

Body.propTypes = {
  children: PropTypes.arrayOf(Row),
};

//
// Row
//

function Row({
  style: propStyle,
  className,
  horizontal,
  children,
  ...otherProps
}) {
  const rowClasses = {
    'flex-row': horizontal,
    'flex-column': !horizontal,
  };

  return (
    <div
      className={classNames(className, rowClasses, styles.row)}
      {...otherProps}>
      {children}
    </div>
  );
}

FlexTable.Row = Row;

Row.propTypes = {
  horizontal: PropTypes.bool, // Vertical is the default
  children: PropTypes.arrayOf(Cell),
};
