import React, { useRef } from 'react';

import { format } from '@/services';
import { useMedia } from '@/hooks';
import { AlternatingRows } from '@/components';
import { Table, UncontrolledTooltip } from 'reactstrap';

export default function LineItems({ services, ...props }) {
  return (
    <div {...props}>
      {services.map((location, idx) => (
        <LineItemDetail key={idx} location={location} />
      ))}
    </div>
  );
}

function LineItemDetail({ location }) {
  const isCollapsed = useMedia(
    ['(min-width: 960px)', 'print'],
    [false, false],
    true
  );

  return (
    <div className="d-flex flex-column">
      <div className="border-bottom-3">
        <strong>{location.booth}</strong>
      </div>
      <AlternatingRows className="p-3" secondaryClass="bg-light">
        {location.services.map((service, idx) => (
          <div key={idx} className="mt-2">
            <div className="text-size-sm">
              <div className="d-flex justify-content-between align-items-end mb-2">
                <div>
                  <div className="text-size-md">
                    <div className="text-size-sm">
                      <em>{service.categoryName}</em>
                    </div>
                    <strong>{service.serviceName}</strong>
                  </div>
                  {service.description && <div>{service.description}</div>}
                </div>
                <div className="text-right text-nowrap">
                  Order# {service.orderID}
                </div>
              </div>
            </div>
            {isCollapsed ? (
              <CollapsedTable service={service} />
            ) : (
              <RegularTable service={service} />
            )}
          </div>
        ))}
      </AlternatingRows>
      <div className="d-flex justify-content-between color-gray-700 border-top mb-3 mt-1 pt-1">
        <div>
          <strong>Subtotal</strong>
        </div>
        <div>{format.currency(location.subtotal)}</div>
      </div>
    </div>
  );
}

function RegularTable({ service }) {
  const quantityTipRef = useRef();

  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Qty</th>
          <th>List Price</th>
          <th>Adv. Discount</th>
          <th>Expedite Fee</th>
          <th>Adj. Price</th>
          <th>Addt'l Discount</th>
          <th>Est'd Taxes</th>
          <th>Est'd Fees</th>
          <th>Item Total</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-nowrap">
            {service.adjustedQuantity}
            {service.initialQuantity > service.adjustedQuantity && (
              <>
                {' '}
                <div className="d-inline-block mr-2">
                  <strong className="text-strike">
                    {service.initialQuantity}
                  </strong>{' '}
                  <i
                    ref={quantityTipRef}
                    className="icon-tooltip text-size-sm"></i>
                </div>
                <UncontrolledTooltip target={quantityTipRef}>
                  Your quantity changed due to an adjustment or refund.
                </UncontrolledTooltip>
              </>
            )}
          </td>
          <td>{format.currency(service.basePrice)}</td>
          <td>{format.percent(service.earlyDiscountPercent, 0)}</td>
          <td>{format.percent(service.expediteFeePercent, 0)}</td>
          <td>{format.currency(service.itemPrice)}</td>
          <td>{format.percent(service.offeredDiscountPercent, 0)}</td>
          <td>{format.currency(service.itemTaxes)}</td>
          <td>{format.currency(service.itemFees)}</td>
          <td>{format.currency(service.itemTotal)}</td>
          <td>{format.currency(service.subtotal)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

function CollapsedTable({ service }) {
  const quantityTipRef = useRef();

  return (
    <div className="border-top">
      <div className="d-flex justify-content-between">
        <div>Quantity</div>
        <div>
          {service.initialQuantity > service.adjustedQuantity && (
            <>
              <div className="d-inline-block mr-2">
                <strong className="text-strike">
                  {service.initialQuantity}
                </strong>{' '}
                <i
                  ref={quantityTipRef}
                  className="icon-tooltip text-size-sm"></i>
              </div>
              <UncontrolledTooltip target={quantityTipRef}>
                Your quantity changed due to an adjustment or refund.
              </UncontrolledTooltip>
            </>
          )}
          {service.adjustedQuantity}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>List Price</div>
        <div>{format.currency(service.basePrice)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Advanced Discount</div>
        <div>{format.percent(service.earlyDiscountPercent, 0)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Expedite Fee</div>
        <div>{format.percent(service.expediteFeePercent, 0)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Adjusted Price</div>
        <div>{format.currency(service.itemPrice)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Additional Discount</div>
        <div>{format.percent(service.offeredDiscountPercent, 0)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Estimated Taxes</div>
        <div>{format.currency(service.itemTaxes)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Estimated Fees</div>
        <div>{format.currency(service.itemFees)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Item Total</div>
        <div>{format.currency(service.itemTotal)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Subtotal</div>
        <div>{format.currency(service.subtotal)}</div>
      </div>
    </div>
  );
}
