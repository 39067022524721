/**
 * delay
 * @summary Produces a delay and returns a promise.
 * @param {Number} ms The number of milliseconds to elapse before the promise resolves.
 */
export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

/**
 * getPublicFilePath
 * @summary Gets the full path for a public file.
 * @param {String} fileName The file name without path information.
 */
export function getPublicFilePath(fileName) {
  return `${process.env.PUBLIC_URL}/files/${fileName}`;
}
