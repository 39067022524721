import React from 'react';

import { Table } from 'reactstrap';
import { useMedia } from '@/hooks';
import { FlexTable, TableContentLoader } from '@/components';

export default function WifiAccessCodesList({
  wifiAccessCodes,
  isLoading,
  ...otherProps
}) {
  const isCollapsed = useMedia(
    ['(min-width: 960px)', 'print'],
    [false, false],
    true
  );

  return (
    <TableContentLoader show={isLoading} {...otherProps}>
      {isCollapsed ? (
        <CollapsedTable
          className="mx-n3 mt-n3"
          wifiAccessCodes={wifiAccessCodes}
        />
      ) : (
        <RegularTable wifiAccessCodes={wifiAccessCodes} />
      )}
    </TableContentLoader>
  );
}

function CollapsedTable({ wifiAccessCodes, ...otherProps }) {
  return (
    <FlexTable striped secondaryClass="bg-light" {...otherProps}>
      <FlexTable.Head>
        <FlexTable.Cell>Access Code</FlexTable.Cell>
        <FlexTable.Cell>Speed</FlexTable.Cell>
        <FlexTable.Cell>Allowed Users</FlexTable.Cell>
        <FlexTable.Cell>Usage</FlexTable.Cell>
        <FlexTable.Cell>Valid Period</FlexTable.Cell>
      </FlexTable.Head>
      <FlexTable.Body>
        {wifiAccessCodes.map((code) => (
          <FlexTable.Row key={code.accessCode}>
            <FlexTable.Cell>
              <div className="text-size-md text-bold">{code.accessCode}</div>
            </FlexTable.Cell>
            <FlexTable.Cell>{code.speed}</FlexTable.Cell>
            <FlexTable.Cell>{code.allowedUsers}</FlexTable.Cell>
            <FlexTable.Cell>{code.usage}</FlexTable.Cell>
            <FlexTable.Cell style={{ width: '100%', textAlign: 'right' }}>
              {new Date(code.startDate).toDateString()} &mdash;{' '}
              {new Date(code.endDate).toDateString()}
            </FlexTable.Cell>
          </FlexTable.Row>
        ))}
      </FlexTable.Body>
    </FlexTable>
  );
}

function RegularTable({ wifiAccessCodes, ...otherProps }) {
  return (
    <Table responsive size="sm" {...otherProps}>
      <thead>
        <tr>
          <th>Access Code</th>
          <th>Speed</th>
          <th>Allowed Users</th>
          <th>Usage</th>
          <th>Valid Period</th>
        </tr>
      </thead>
      <tbody>
        {wifiAccessCodes.map((code) => (
          <tr key={code.accessCode}>
            <td>
              <span className="text-size-md text-bold">{code.accessCode}</span>
            </td>
            <td>{code.speed}</td>
            <td>{code.allowedUsers}</td>
            <td>{code.usage}</td>
            <td>
              {new Date(code.startDate).toDateString()} &mdash;{' '}
              {new Date(code.endDate).toDateString()}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
