import React from 'react';
import classNames from 'classnames';

import youtubeLogo from '@/assets/images/social-youtube.svg';
import twitterLogo from '@/assets/images/social-twitter.svg';
import linkedInLogo from '@/assets/images/social-linkedin.svg';

export default function SocialLinks({ className }) {
  return (
    <div
      className={classNames(
        className,
        'd-flex flex-lg-column align-items-start'
      )}>
      <div className="position-relative bg-light mr-1 mb-1 p-3">
        <a
          className="stretched-link"
          href="https://www.linkedin.com/company/hospitalitynetwork"
          target="_blank"
          rel="noreferrer">
          <img src={linkedInLogo} alt="Linked In" />
        </a>
      </div>
      <div className="position-relative bg-light mr-1 mb-1 p-3">
        <a
          className="stretched-link"
          href="https://twitter.com/hn_network"
          target="_blank"
          rel="noreferrer">
          <img src={twitterLogo} alt="Twitter" />
        </a>
      </div>
      <div className="position-relative bg-light p-3">
        <a
          className="stretched-link"
          href="https://www.youtube.com/channel/UCY93BmWEGGI8neAHm1R7-cQ"
          target="_blank"
          rel="noreferrer">
          <img src={youtubeLogo} alt="YouTube" />
        </a>
      </div>
    </div>
  );
}
