import React, { useState, useEffect } from 'react';

import { httpRequest, reduceStatus } from '@/services';
import { LoadingProvider } from '@/context';
import { useLoad, useDebounce } from '@/hooks';
import { usePlaceOrderContext } from '../context';
import { TextInput, CheckInput } from '@/components/form';
import { Row, Col, Button, Label, FormGroup, Fade } from 'reactstrap';
import { Hero, BodyContainer, Loader, FormSection } from '@/components';

import {
  default as AddressForm,
  makeState,
  makeCountry,
} from '../components/AddressForm';

export default function BillingInfo() {
  const { moveBack, formValues, isSubmitting, setFieldValue } =
    usePlaceOrderContext();

  const { companyAddress, billingContact } = formValues;
  const companyCountryCode = companyAddress?.countryCode;
  const billingCountryCode = billingContact?.address?.countryCode;
  const companyPostalCode = useDebounce(companyAddress?.postalCode);
  const billingPostalCode = useDebounce(billingContact?.address?.postalCode);
  const isBillingAddress = companyAddress?.isBillingAddress ?? false;

  const [countries, setCountries] = useState([]);
  const [companyStates, setCompanyStates] = useState([]);
  const [billingStates, setBillingStates] = useState([]);

  // Initial fetch...
  const { status: countryLoadStatus } = useLoad(() =>
    Promise.all([
      httpRequest('api/countries').then(setCountries),
      httpRequest(`api/countries/${companyCountryCode}/states`).then((data) =>
        setCompanyStates(data)
      ),
    ])
  );

  const { status: companyStateLoadStatus } = useLoad(() => {
    if (companyCountryCode) {
      return !!billingStates || companyCountryCode !== billingCountryCode
        ? httpRequest(`api/countries/${companyCountryCode}/states`).then(
            (data) => setCompanyStates(data)
          )
        : setCompanyStates(billingStates);
    }
  }, [companyCountryCode]);

  const { status: billingStateLoadStatus } = useLoad(() => {
    if (billingCountryCode) {
      return billingCountryCode !== companyCountryCode
        ? httpRequest(`api/countries/${billingCountryCode}/states`).then(
            (data) => setBillingStates(data)
          )
        : setBillingStates(companyStates); // returns undefined
    }
  }, [billingCountryCode]);

  const { status: companyZipLoadStatus } = useLoad(() => {
    if (companyPostalCode?.length >= 5 && companyCountryCode === 'US') {
      return httpRequest(`api/lookup/zip/${companyPostalCode}`).then((data) => {
        setFieldValue('companyAddress.city', data.city);
        setFieldValue('companyAddress.stateCode', data.stateCode);
        setFieldValue('companyAddress.postalCode', data.postalCode);
      });
    }
  }, [companyPostalCode]);

  const { status: billingZipLoadStatus } = useLoad(() => {
    if (billingPostalCode?.length >= 5 && billingCountryCode === 'US') {
      return httpRequest(`api/lookup/zip/${billingPostalCode}`).then((data) => {
        setFieldValue('billingContact.address.city', data.city);
        setFieldValue('billingContact.address.stateCode', data.stateCode);
        setFieldValue('billingContact.address.postalCode', data.postalCode);
      });
    }
  }, [billingPostalCode]);

  useEffect(() => {
    isBillingAddress
      ? setFieldValue('billingContact.address', undefined)
      : setFieldValue('billingContact.address.countryCode', 'US');
  }, [isBillingAddress]);

  const { isLoading } = reduceStatus(
    countryLoadStatus,
    companyStateLoadStatus,
    billingStateLoadStatus,
    companyZipLoadStatus,
    billingZipLoadStatus
  );

  return (
    <LoadingProvider isLoading={isLoading}>
      <Loader scrollToTop={false}>
        <Hero size="sm" className="text-medium shadow-sm px-4">
          <h2>Billing Information</h2>
        </Hero>
        <BodyContainer>
          <FormSection title="Billing Contact">
            <FormGroup>
              <Label>Company Name</Label>
              <TextInput
                name="billingContact.companyName"
                placeholder="Company Name"
              />
            </FormGroup>
            <Row form>
              <Col md={9}>
                <FormGroup>
                  <Label>First Name</Label>
                  <TextInput
                    name="billingContact.firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Last Name</Label>
                  <TextInput
                    name="billingContact.lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={9}>
                <FormGroup>
                  <Label>Phone</Label>
                  <TextInput
                    name="billingContact.phone"
                    placeholder="000-000-0000"
                  />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Fax</Label> (Optional)
                  <TextInput
                    name="billingContact.fax"
                    placeholder="000-000-0000"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={9}>
                <FormGroup>
                  <Label>Email</Label>
                  <TextInput
                    name="billingContact.email"
                    placeholder="name@address.com"
                  />
                </FormGroup>
              </Col>
            </Row>
          </FormSection>
          <FormSection title="Company Address">
            <AddressForm
              namePrefix="companyAddress"
              stateOptions={
                companyCountryCode
                  ? companyStates.map(({ stateCode, stateName, countryCode }) =>
                      makeState(stateCode, stateName, countryCode)
                    )
                  : null
              }
              countryOptions={countries.map(({ countryCode, countryName }) =>
                makeCountry(countryCode, countryName)
              )}
            />
            <FormGroup className="mt-4">
              <CheckInput
                name="companyAddress.isBillingAddress"
                label="Use this as my billing address"
              />
            </FormGroup>
          </FormSection>
          {!isBillingAddress && (
            <Fade>
              <FormSection title="Billing Address">
                <AddressForm
                  namePrefix="billingContact.address"
                  stateOptions={
                    billingCountryCode
                      ? billingStates.map(
                          ({ stateCode, stateName, countryCode }) =>
                            makeState(stateCode, stateName, countryCode)
                        )
                      : null
                  }
                  countryOptions={countries.map(
                    ({ countryCode, countryName }) =>
                      makeCountry(countryCode, countryName)
                  )}
                />
              </FormSection>
            </Fade>
          )}
          <Row className="mt-4">
            <Col className="d-flex flex-wrap">
              <Button
                type="submit"
                color="primary"
                className="btn-form order-lg-2 mb-4 mb-lg-0 ml-lg-3"
                disabled={isSubmitting}>
                Next
              </Button>
              <Button
                outline
                type="button"
                color="primary"
                className="btn-form order-lg-1"
                onClick={moveBack}>
                Back
              </Button>
            </Col>
          </Row>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}
