import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { httpRequest } from '@/services';
import { Button, TermsModal } from '@/components';
import { useLoad, useToggle } from '@/hooks';
import { TextInput, CheckInput } from '@/components/form';
import { FormGroup, FormText, Label } from 'reactstrap';

import { signatureSchema } from '../schema';

export default function SignatureForm({ confirmationNumber, onSigned, message }) {
  const signatureLoad = useLoad(
    (signature) =>
      httpRequest
        .post(`api/order/conf/${confirmationNumber}/sign`, {
          payload: signature,
        })
        .then(() => onSigned({ signature })),
    { autoLoad: false }
  );

  const [showTerms, toggleShowTerms] = useToggle();
  function handleShowTerms(e) {
    e.preventDefault();
    toggleShowTerms();
  }

  function handleSubmit({ name: signature }) {
    signatureLoad.load(signature);
  }

  return (
    <Formik
      initialValues={signatureSchema.defaultValues}
      validationSchema={signatureSchema.validation}
      onSubmit={handleSubmit}>
      <Form>
        <>
          <FormGroup>
            <Label>Customer Signature (printed)</Label>
            <TextInput name="name" placeholder="Customer Signature" />
          </FormGroup>
          <FormGroup>
            <CheckInput
              touched
              name="acceptTerms"
              label={
                <>
                  I agree to the{' '}
                  <Button color="inline" onClick={handleShowTerms}>
                    Terms and Conditions
                  </Button>
                  <FormText>
                    <em>
                      By clicking the box above, and proceeding with this order,
                      you are agreeing to the terms and conditions set forth in
                      the link above on behalf of your company. By proceeding
                      with this order, you agree that you are authorized to
                      enter into these terms and conditions on behalf of your
                      company.
                    </em>
                  </FormText>
                </>
              }
            />
            <TermsModal
              showTerms={showTerms}
              toggleShowTerms={toggleShowTerms}
              message={message}
            />
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mt-3"
            isLoading={signatureLoad.status.isLoading}>
            Submit Signature
          </Button>
        </>
      </Form>
    </Formik>
  );
}

SignatureForm.propTypes = {
  confirmationNumber: PropTypes.string.isRequired,
  onSigned: PropTypes.func.isRequired,
  message: PropTypes.string
};
