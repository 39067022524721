import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Jumbotron } from 'reactstrap';

export default function Hero({ size, className, children, ...otherProps }) {
  const classes = classNames(className, 'mx-n3', {
    'jumbotron-sm': size === 'sm',
  });

  return (
    <Jumbotron className={classes} {...otherProps}>
      {children}
    </Jumbotron>
  );
}

Hero.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
};
