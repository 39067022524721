import React, { useState } from 'react';

import { useLoad } from '@/hooks';
import { LoadingProvider } from '@/context';
import { usePlaceOrderContext } from '../context';
import { TextInput, FileInput } from '@/components/form';
import { httpRequest, reduceStatus } from '@/services';
import BoothLayoutLinks from '../components/BoothLayoutLinks';

import {
  default as SelectInput,
  makePair,
} from '@/components/form/SelectInput';

import { Row, Col, Button, FormGroup, Label, FormText } from 'reactstrap';

import {
  Hero,
  BodyContainer,
  Loader,
  FormSection,
  SkeletonLoader,
} from '@/components';

import settings from '@/settings';

export default function EventInfo({ locationID, location }) {
  const [halls, setHalls] = useState([]);
  const [events, setEvents] = useState([]);

  const { status: loadStatus } = useLoad(() => {
    return Promise.all([
      httpRequest(`api/location/${locationID}/events/current`).then(setEvents),
      httpRequest(`api/location/${locationID}/halls`).then(setHalls),
    ]);
  }, [locationID]);

  const {
    moveBack,
    isSubmitting,
    loaderStatus: parentLoaderStatus,
  } = usePlaceOrderContext();

  const acceptedFileTypes = settings.acceptedFileTypes.map((v) => v.type);

  return (
    <LoadingProvider {...reduceStatus(loadStatus, parentLoaderStatus)}>
      <Loader>
        <Hero className="jumbotron-sm text-medium shadow-sm px-4">
          <h2>Event Information</h2>
        </Hero>
        <BodyContainer>
          <FormSection title="Order Details">
            <FormGroup>
              <Label>Location</Label>
              <div>
                <SkeletonLoader type="text" width={200}>
                  {() => location.locationName}
                </SkeletonLoader>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Event</Label>
              <SelectInput
                isNumber
                name="event.eventID"
                placeholder="Select an Event"
                options={events.map(({ eventID, eventName }) =>
                  makePair(eventID, eventName)
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label>Hall</Label>
              <SelectInput
                isNumber
                name="event.hallID"
                placeholder="Select a Hall"
                options={halls.map(({ hallID, hallName }) =>
                  makePair(hallID, hallName)
                )}
              />
            </FormGroup>
            <Row form>
              <FormGroup className="col-12">
                <Label>Booth/Room/Sign Number</Label>
                <TextInput
                  name="event.boothNumber"
                  placeholder="Booth/Room/Sign #"
                />
              </FormGroup>
            </Row>
            <FormGroup>
              <Label>Booth Layout</Label> (Optional)
              <FileInput
                name="event.boothLayoutFile"
                placeholder="Select File"
                maxFileSize={settings.maxAllowedUploadSize}
                accept={acceptedFileTypes}
              />
              <FormText>
                Please upload a file that describes the desired placement of
                services in your booth. If no file is uploaded with your order,
                all drops will be delivered to the center of the booth.{' '}
                {location && <BoothLayoutLinks location={location} />}
                <br />
                Allowed file types: PDF and Image files. Max file size: 4MB.
              </FormText>
            </FormGroup>
          </FormSection>
          <FormSection title="Company On-Site Contact">
            <Row form>
              <Col md={9}>
                <FormGroup>
                  <Label>First Name</Label>
                  <TextInput
                    name="onsiteContact.firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Last Name</Label>
                  <TextInput
                    name="onsiteContact.lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={9}>
                <FormGroup>
                  <Label>Phone</Label>
                  <TextInput
                    name="onsiteContact.phone"
                    placeholder="000-000-0000"
                  />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label>Email</Label>
                  <TextInput
                    name="onsiteContact.email"
                    placeholder="name@address.com"
                  />
                </FormGroup>
              </Col>
            </Row>
          </FormSection>
          <Row className="mt-4">
            <Col className="d-flex flex-wrap">
              <Button
                type="submit"
                color="primary"
                className="btn-form order-lg-2 mb-4 mb-lg-0 ml-lg-3"
                disabled={isSubmitting}>
                Next
              </Button>
              <Button
                outline
                type="button"
                color="primary"
                className="btn-form order-lg-1"
                onClick={moveBack}>
                Back
              </Button>
            </Col>
          </Row>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}

EventInfo.propTypes = {};
