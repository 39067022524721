import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormHeader.module.scss';

export default function FormHeader(props) {
  return (
    <h5 className={styles['form-header']}>
      <strong>{props.title}</strong>
    </h5>
  );
}

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
