import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import scrollToTop from '../services/scrollToTop';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(scrollToTop, [pathname]);
  useLayoutEffect(scrollToTop, [pathname]);
  return null;
}
