import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './Incrementor.module.scss';

export default function Incrementor({
  name,
  value,
  onChange,
  allowNegative = true,
}) {
  const [, /*field*/ meta, helper] = useField(name);
  const fieldValue = meta.value || 0;

  const disableDecrement = !allowNegative && fieldValue === 0;

  function handleClick(change) {
    if (change < 0 && disableDecrement) return;

    const newCount = (meta.value || 0) + change;
    if (!allowNegative && newCount < 0) {
      return;
    }

    helper.setTouched(true);
    helper.setValue(newCount);

    if (onChange) {
      onChange({
        value: newCount,
        change,
      });
    }
  }

  const decrementClasses = classNames(styles.decrement, {
    [styles.disabled]: disableDecrement,
  });

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={decrementClasses}
        onClick={() => handleClick(-1)}></button>
      <div className={styles.number}>
        {fieldValue !== 0 ? (
          <strong>{fieldValue}</strong>
        ) : (
          <span>{fieldValue}</span>
        )}
      </div>
      <button
        type="button"
        className={styles.increment}
        onClick={() => handleClick(+1)}></button>
    </div>
  );
}

Incrementor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  allowNegative: PropTypes.bool,
};
