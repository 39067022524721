import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoad } from '@/hooks';
import { Alert, Button, Skeleton } from '@/components';
import { httpRequest, format, utility } from '@/services';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';

const { getPublicFilePath } = utility;

export default function LandingPage({ onImageClick }) {
  const navigate = useNavigate();
  const [locations, setLocations] = useState();
  const {
    status: { isLoading, error },
  } = useLoad(() => {
    return httpRequest.get('api/locations').then(setLocations);
  });
  const handleImageClick = async (locationID) => {
    try {
      const locationSettings = await httpRequest.get(`api/locationsettings/${locationID}`);
      const newUrl = new URL(locationSettings.digitalSignageUrl);
      window.location.href = newUrl;      
    } catch (error) {
      console.error('Error fetching location settings data', error);    
    }
  };


  return (
    <>
      <div style={{ paddingTop: '50px', textAlign: 'center' }}>
        <div style={{ margin: '20px auto', padding: '20px' }}>
          <h3>
            <span className="text-nowrap-lg"></span> Cox/HN is the exclusive Internet and Wifi provider at the following convention centers.
          </h3>
          <p>
            In addition to the wide range of connectivity products, Cox Business also provides TV and telephone services.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginTop: '20px' }} className="mt-5">
          {error ? (
            <Alert
              vertical
              type="warning"
              className="w-sm-75 mx-auto"
              messageClass="text-center">
              We're sorry, but we're having a problem loading the convention venues.
              Please try again later.
            </Alert>
          ) : isLoading ? (
            <LandingPageItem isLoading />
          ) : (
            locations &&
            locations.map((location) => (
              <LandingPageItem key={location.locationID} location={location} handleImageClick={() => handleImageClick(location.locationID)} />
            ))
          )}
        </div>
      </div>
    </>
  );
}

function LandingPageItem({ isLoading, location = {}, handleImageClick }) {
  const navigate = useNavigate();
  const [formsPopoverOpen, setFormsPopoverOpen] = useState(false);
  return (

    <Col key={location.locationID} xs={18} lg={5} className="text-center text-lg-right">
      {isLoading ? (
        <Skeleton height={250} width={500} />
      ) : (
          <button onClick={() => handleImageClick(location.locationID)} className="btn p-0 border-0 bg-transparent">
          {location.venueImageFileName && (
            <img
              className="img-fluid shadow-lg-offset-l"
              src={getPublicFilePath(location.venueImageFileName)}
              alt={location.locationName}
              text={location.locationName}
            />
          )}
          <h5 className="mt-3 text-center">{location.locationName}</h5>
        </button>
      )}      
    </Col>
  );
}

