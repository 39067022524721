import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _concat from 'lodash/concat';

import { PlaceOrderContext } from '../context';
import { format, httpRequest } from '@/services';
import { mapServiceObjToArray } from '../services';
import { LoadingProvider } from '@/context/LoadingContext';
import { useLoad, useNavigate, useToggle } from '@/hooks';
import { CheckInput, ErrorMessages } from '@/components/form';
import { Row, Col, Label, Spinner, FormText, FormGroup } from 'reactstrap';

import {
  Hero,
  BodyContainer,
  Button,
  Loader,
  Alert,
  FlexTable,
  FormHeader,
  FormSection,
  TableContentLoader,
  SkeletonLoader,
  TermsModal,
} from '@/components';

export default function VerifyOrder({ eventID, locationID }) {
  const navigate = useNavigate();

  const {
    orderState,
    createOrder,
    resetState,
    validate,
    isSubmitting,
    setSubmitting,
    moveBack,
  } = useContext(PlaceOrderContext);

  const { event, onsiteContact, companyAddress, billingContact, services } =
    orderState;

  // Load the order data for verification.
  const [verifyInfo, setVerifyInfo] = useState({});
  const { status: loadStatus } = useLoad(() => {
    const payload = {
      locationID,
      eventID,
      services: mapServiceObjToArray(services),
    };
    return httpRequest
      .post(`api/order/verification`, {
        payload,
      })
      .then(setVerifyInfo);
  });

  // We handle the final submit of the form here
  // and redirection to the payment gateway.
  // `createOrder` is provided by the PlaceOrder component.
  const [submitErrors, setSubmitErrors] = useState();
  const [isSubmitError, setIsSubmitError] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    const errors = await validate();

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);
      setSubmitErrors(null);
      setIsSubmitError(false);

      try {
        const result = await createOrder(verifyInfo.grandTotal);
        resetState(null);
        navigate.gotoUrl(result.paymentGatewayUrl);
      } catch (error) {
        const { result, response } = error;
        setIsSubmitError(true);
        if (response?.status === 400 && result?.errors) {
          const errors = _concat(Object.values(result.errors));
          setSubmitErrors(errors);
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  const [showTerms, toggleShowTerms] = useToggle();
  function handleShowTerms(e) {
    e.preventDefault();
    toggleShowTerms();
  }

    const billingAddress = billingContact?.address ?? companyAddress;

  return (
    <LoadingProvider {...loadStatus}>
      <Loader>
        <Hero size="sm" className="shadow-sm px-4">
          <h2 className="text-medium">Verify Your Order</h2>
          <p>
            Please verify all of the information below is correct.
            <br className="d-none d-sm-block" /> Click Submit to continue to our
            payment gateway.
          </p>
        </Hero>
        <BodyContainer>
          <FormSection
            title="Event Information"
            titleClass="bg-gray-700 text-white">
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Location</Label>
                  <div>
                    <SkeletonLoader width={300}>
                      {() => verifyInfo.locationName}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Event</Label>
                  <div>
                    <SkeletonLoader width={300}>
                      {() => verifyInfo.eventName}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Booth/Room #</Label>
                  <div>{event.boothNumber}</div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Booth Type</Label>
                  <div>Standard</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label>Booth Layout File</Label>
                <div>
                  {event.boothLayoutFile ? (
                    <>
                      {event.boothLayoutFile.name} (
                      {Math.floor(event.boothLayoutFile.size / 1024)} KB)
                    </>
                  ) : (
                    <>(No file provided)</>
                  )}
                </div>
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection
            title="Exhibitor Information"
            titleClass="bg-gray-700 text-white">
            <Row>
              <FormHeader title="Company Information" />
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label>Company Name</Label>
                  <div>{billingContact.companyName}</div>
                </FormGroup>
              </Col>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Company Address</Label>
                  <div>
                    {format.address(companyAddress, {
                      multiline: true,
                      showCountry: true,
                    })}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <FormHeader title="On-Site Contact" />
            </Row>
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Name</Label>
                  <div>
                    {onsiteContact.firstName}&nbsp;
                    {onsiteContact.lastName}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Phone</Label>
                  <div>{format.phoneNumber(onsiteContact.phone)}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label>Email</Label>
                <div>{onsiteContact.email}</div>
              </FormGroup>
            </Row>
            <Row className="mt-3">
              <FormHeader title="Billing Information" />
            </Row>
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Name</Label>
                  <div>
                    {billingContact.firstName}&nbsp;
                    {billingContact.lastName}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Phone</Label>
                  <div>{format.phoneNumber(billingContact.phone)}</div>
                </FormGroup>
              </Col>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Fax</Label>
                  <div>
                    {billingContact.fax
                      ? format.phoneNumber(billingContact.fax)
                      : '(None provided)'}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={18} md={9}>
                <FormGroup>
                  <Label>Email</Label>
                  <div>{billingContact.email}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label>Address</Label>
                <div>
                  {format.address(billingAddress, {
                    multiline: true,
                    showCountry: true,
                  })}
                </div>
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection title="Services" titleClass="bg-gray-700 text-white">
            <TableContentLoader show={!verifyInfo.services}>
              <FlexTable
                striped
                secondaryClass="bg-light"
                className="mx-n3 mb-2 mt-n3">
                <FlexTable.Head>
                  <FlexTable.Cell>Quantity</FlexTable.Cell>
                  <FlexTable.Cell>Price</FlexTable.Cell>
                  <FlexTable.Cell>Estimated Tax</FlexTable.Cell>
                  <FlexTable.Cell>Estimated Fees</FlexTable.Cell>
                  <FlexTable.Cell>Extended Price</FlexTable.Cell>
                </FlexTable.Head>
                <FlexTable.Body>
                  {verifyInfo.services &&
                    verifyInfo.services.map((service) => (
                      <FlexTable.Row key={service.serviceID}>
                        <FlexTable.Cell noHeader className="mb-3">
                          <div className="text-size-md text-bold">
                            {service.serviceName}
                          </div>
                          <div>{service.description}</div>
                        </FlexTable.Cell>
                        <FlexTable.Cell>{service.quantity}</FlexTable.Cell>
                        <FlexTable.Cell>
                          {format.currency(service.price)}
                        </FlexTable.Cell>
                        <FlexTable.Cell>
                          {format.currency(service.totalTaxes)}
                        </FlexTable.Cell>
                        <FlexTable.Cell>
                          {format.currency(service.totalFees)}
                        </FlexTable.Cell>
                        <FlexTable.Cell>
                          {format.currency(service.totalPrice)}
                        </FlexTable.Cell>
                      </FlexTable.Row>
                    ))}
                </FlexTable.Body>
              </FlexTable>
              <div className="d-flex text-size-sm bt-double pt-2">
                <div className="text-bold mr-auto">Subtotal</div>
                <div>{format.currency(verifyInfo.subtotal)}</div>
              </div>
              <div className="d-flex text-size-sm">
                <div className="text-bold mr-auto">Estimated Total Tax</div>
                <div>{format.currency(verifyInfo.totalTaxes)}</div>
              </div>
              <div className="d-flex text-size-sm">
                <div className="text-bold mr-auto">Estimated Total Fees</div>
                <div>{format.currency(verifyInfo.totalFees)}</div>
              </div>
              <div className="d-flex">
                <div className="text-bold mr-auto">Grand Total</div>
                <div>{format.currency(verifyInfo.grandTotal)}</div>
              </div>
            </TableContentLoader>
            <FormGroup className="mt-5">
              <CheckInput
                noError
                touched
                disabled={isSubmitting}
                name="verification.acceptTerms"
                label={
                  <>
                    I agree to the{' '}
                    <Button color="inline" onClick={handleShowTerms}>
                      Terms and Conditions
                    </Button>
                    <FormText>
                      <em>
                        By clicking the box above, and proceeding with this
                        order, you are agreeing to the terms and conditions set
                        forth in the link above on behalf of your company. By
                        proceeding with this order, you agree that you are
                        authorized to enter into these terms and conditions on
                        behalf of your company.
                      </em>
                    </FormText>
                  </>
                }
              />
              <TermsModal
                showTerms={showTerms}
                toggleShowTerms={toggleShowTerms}
                message={verifyInfo.termsAndConditions}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <CheckInput
                disabled={isSubmitting}
                name="verification.subscribeToNewsletter"
                label="Yes, I would like to subscribe to the newsletter"
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <small>
                <em>
                  Taxes and fees are additional and subject to change from time
                  to time. Customer agrees to pay Cox Business for any
                  additional taxes and fees that are not listed on this page
                  upon receipt of an invoice from Cox Business.
                </em>
              </small>
            </FormGroup>
            <ErrorMessages alwaysShow />
            {isSubmitError &&
              (submitErrors ? (
                <Alert type="warning" items={submitErrors} />
              ) : (
                <Alert vertical type="warning">
                  We're sorry, but your order could not be submitted at this
                  time. Please try again shortly or contact a customer
                  representative to place your order.
                </Alert>
              ))}
          </FormSection>
          <Row className="mt-5">
            <Col className="d-flex flex-wrap">
              <Button
                type="submit"
                color="primary"
                className="btn-form order-lg-2 mb-4 mb-lg-0 ml-lg-3"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loader={
                  <>
                    <Spinner size="sm" color="light" /> Placing order...
                  </>
                }>
                Submit &amp; Pay
              </Button>
              <Button
                outline
                type="button"
                color="primary"
                className="btn-form order-lg-1"
                disabled={isSubmitting}
                onClick={moveBack}>
                Back
              </Button>
            </Col>
          </Row>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}

VerifyOrder.propTypes = {
  locationID: PropTypes.number.isRequired,
  eventID: PropTypes.number.isRequired,
};
