import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, FormFeedback } from 'reactstrap';
import useFormikFieldFast from '@/hooks/form/useFormikFieldFast';

export default function TextInput({ type, name, ...otherProps }) {
  const [field, meta] = useFormikFieldFast(name);
  const [cursor, setCursor] = useState();
  const ref = useRef();

  const isError = meta.touched && meta.error != null;
  const { value, onChange, ...otherField } = field;
  const fieldValue = value || '';

  // Handle the case where when a user tries to prepend text to
  // what's existing in the controlled field, the cursor skips
  // to the end of the line while typing.
  // Note: setSelectionRange is only supported on certain input types.
  useEffect(() => {
    const input = ref.current;
    if (input && input.type === 'text') input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange(e);
  };

  return (
    <>
      <Input
        innerRef={ref}
        id={name}
        type={type || 'text'}
        value={fieldValue}
        onChange={handleChange}
        invalid={isError}
        spellCheck={false}
        {...otherProps}
        {...otherField}
      />
      <FormFeedback valid={false}>{meta.error}</FormFeedback>
    </>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'textarea', 'email', 'tel', 'checkbox']),
};
