import { useReducer, useEffect } from 'react';

const defaultOptions = {
  timeout: 0,
};

export default function useToggle(initialValue = false, options) {
  const [state, setState] = useReducer((state) => !state, initialValue);

  // Set option defaults.
  options = Object.assign(defaultOptions, options);

  useEffect(() => {
    // If timeout, set it.
    let handle;
    if (state && options.timeout > 0) {
      handle = setTimeout(() => {
        setState();
      }, options.timeout);
    }

    return () => {
      clearTimeout(handle);
    };
  }, [state, setState, options]);

  return [state, setState];
}
