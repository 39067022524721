import React, { Component } from 'react';
import { NavMenu, Footer } from '@/components';
import { Container, Row, Col } from 'reactstrap';

export default class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container fluid className="mb-4">
          <Row>
            <Col>{this.props.children}</Col>
          </Row>
          <Footer />
        </Container>
      </div>
    );
  }
}
