import PropTypes from 'prop-types';

export const videoPostShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  previewImage: PropTypes.shape({
    fullSize: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
  }),
  publishedOn: PropTypes.string,
});
