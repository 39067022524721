
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTitle, useNavigate, useLoad } from '@/hooks';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import LocationList from './LocationList';
import { Hero, BodyContainer, Button, Skeleton } from '@/components';
import { httpRequest, format, utility } from '@/services';
import styles from './Home.module.scss';
import settings from '@/settings';
const { getPublicFilePath } = utility;
const { WiFiIcon, WiredInternetIcon, VoiceVideoIcon } = settings;

export default function Home({ locationID }) {
  useTitle('Cox Convention Portal');
  const [locations, setLocations] = useState();
  const [locationSettings, setLocationSettings] = useState();
  const [locationDetail, setLocationDetail] = useState();
  const navigate = useNavigate();
  const [formsPopoverOpen, setFormsPopoverOpen] = useState(false);
  const {
    status: { isLoading, error },
  } = useLoad(() => {
    return httpRequest.get('api/locations').then(setLocations);
  });
  const subdomain = window.location.hostname.split('.')[0];
  const locationname = subdomain.includes('-') ? subdomain.split('-')[1] : subdomain;
  const filteredLocations = locations?.filter(
    (location) => location.venueImageFileName.includes(locationname)
  );
  if (filteredLocations) {
    const filteredLocationID = filteredLocations.length > 0 ? filteredLocations[0].locationID : null;
    sessionStorage.setItem('locationID', JSON.stringify(filteredLocationID));
  };
  return (
    <>
      <div>
        {filteredLocations && filteredLocations.map((filteredlocation) => (
          <Col key={filteredlocation.locationID}>
            <HeaderItem location={filteredlocation} />
          </Col>
        ))}

      </div>
      <BodyContainer>
      </BodyContainer>
      <div>
        {filteredLocations && filteredLocations.map((filteredlocation) => (
          <Col key={filteredlocation.locationID}>
            <LocationDetails location={filteredlocation} />
          </Col>
        ))}

      </div>
      <div>
        {filteredLocations && filteredLocations.map((filteredlocation) => (
          <Col key={filteredlocation.locationID}>
            <EventTechnologyServices location={filteredlocation} />
          </Col>
        ))}

      </div>
      <div>
        {filteredLocations && filteredLocations.map((filteredlocation) => (
          <Col key={filteredlocation.locationID}>
            <NeedAssistance location={filteredlocation} />
          </Col>
        ))}

      </div>
      <Row></Row>
    </>
  );
}
function HeaderItem({ location = {} }) {
  const navigate = useNavigate();
  const [formsPopoverOpen, setFormsPopoverOpen] = useState(false);

  return (
    <Row key={location.locationID} className="align-items-md-center mb-4">
      <Col>
        <Hero className={classNames(styles['hero'])}>
          <Row className="h-100 align-items-md-center">
            <Col
              md={10}
              lg={9}
              xl={{ size: 8, offset: 2 }}
              className={classNames(
                styles['copy'],
                'text-center text-md-left px-sm-5 pl-md-5 pr-md-4'
              )}>
              <h3>
                Welcome to the{' '}
                <strong>
                  {location.locationName} Internet and technology ordering
                  portal
                </strong>{' '}
                provided by{' '}
                <span className="text-nowrap-lg">
                  Cox Business/Hospitality Network
                </span>
              </h3>
            </Col>
            <Col className={classNames(styles['image'], 'snug-bottom')}></Col>
          </Row>
        </Hero>
      </Col>
    </Row>
  );
}

function LocationDetails({ isLoading, location = {} }) {
  const navigate = useNavigate();
  const [formsPopoverOpen, setFormsPopoverOpen] = useState(false);

  return (
    
    <Row key={location.locationID} className="align-items-md-center mb-6 mt-4">
      <Col xs={18} lg={9} className="text-center text-lg-right">
        {isLoading ? (
          <Skeleton height={250} width={350} />
        ) : (
          location.venueImageFileName && (
            <img
              className="img-fluid"
              src={getPublicFilePath(location.venueImageFileName)}
              alt={location.locationName}
              text={location.locationName}
            />
          )
        )}
      </Col>
      <Col className="text-center text-lg-left mt-4 mt-lg-0">
        {isLoading ? (
          <Skeleton height={150} width={250} />
        ) : (
          <img
            className="img-fluid"
            style={{ width: '50%', maxWidth: '20rem' }}
            src={getPublicFilePath(location.logoFileName)}
            alt={location.locationName}
            text={location.locationName}
          />
        )}
        <Row>
          <Col>
            {isLoading ? (
              <Skeleton width={300} type="address" className="mt-2" />
            ) : (
              <address className="mt-4">
                {format.address(location.address)}
              </address>
            )}
          </Col>
        </Row>
        <Row noGutters className="mt-1">
          <Col lg="auto">
            {isLoading ? (
              <Skeleton type="button" />
            ) : (
              <Button
                color="primary"
                onClick={() => navigate.toPlaceOrder(location.locationID)}>
                Place Order
              </Button>
            )}
          </Col>
          <Col className="mx-auto mx-lg-0 ml-lg-3">
            {isLoading ? (
              <Skeleton type="button" />
            ) : (
              <>
                <Button
                  color="link"
                  id={`forms-button_${location.locationID}`}
                  tabIndex="0">
                  Forms
                </Button>
                <Popover
                  trigger="legacy"
                  placement="top"
                  target={`forms-button_${location.locationID}`}
                  isOpen={formsPopoverOpen}
                  toggle={() => setFormsPopoverOpen(!formsPopoverOpen)}>
                  <PopoverBody>
                    <ul className="list-unstyled">
                      {location.dataOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.dataOrderFormFileName
                            )}>
                            Data Order Form
                          </a>
                        </li>
                      )}
                      {location.voiceVideoOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.voiceVideoOrderFormFileName
                            )}>
                            Voice/Video Order Form
                          </a>
                        </li>
                      )}
                      {location.wifiHotspotOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.wifiHotspotOrderFormFileName
                            )}>
                            WiFi Hotspot Order Form
                          </a>
                        </li>
                      )}
                    </ul>
                  </PopoverBody>
                </Popover>
              </>
            )}
            {isLoading ? (
              <Skeleton type="button" className="ml-3" />
            ) : (
              <Button
                color="link"
                onClick={() => navigate.toContact(location.locationID)}>
                Contact
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row> 

  );
}

function EventTechnologyServices({location = {} }) {
  return (
    <>
      
      <Row className="justify-content-center" style={{ backgroundColor: '#f2f6f7' }}>
          <Col xs={15} md={20}>
        <h2 className="mt-5 font-weight-bold text-center">EVENT TECHNOLOGY SERVICES</h2>
          <p className="mt-4">Cox Business/Hospitality Network is the official Internet and WiFi provider at the {location.locationName}

                  . In addition to the wide range of technology solutions, we take a tailored approach to customer

                  service by consulting with you early on to understand your specific needs, helping to ensure you meet

          your event goals. Click <a href="https://www.coxblue.com/hn-elevates-convention-center-technology-with-business-class-connectivity/" style={{ fontWeight: 'bold' }}>here</a> to learn more.</p>
          </Col>
        </Row>
    
      <Row className="justify-content-center" style={{ backgroundColor: '#f2f6f7', paddingBottom: '40px' }}>
              <Col xs={15} md={5} className="mt-5">
          <img className="img-fluid mx-auto d-block" src={WiredInternetIcon} alt="WiredInternet_logo" width="150px" height="150px" />
                  <h4 className="text-center mt-4 font-weight-bold">WIRED INTERNET</h4>
          <p className="text-center">Internet bandwidth for hotels, convention <br />
            centers, sports stadiums, and other <br />
            large venues.</p>
              </Col>
              <Col xs={15} md={5} className="mt-5">
          <img className="img-fluid mx-auto d-block" src={WiFiIcon} alt="Wifi_logo" width="150px" height="150px" />
          <h4 className="text-center mt-4 font-weight-bold">WIFI</h4>
          <p className="text-center">Custom WiFi networks, including management, <br />
            monetization tools, help desk support <br />
            and NOC services.</p>
        </Col>
        <Col xs={15} md={5} className="mt-5">
          <img className="img-fluid mx-auto d-block" src={VoiceVideoIcon} alt="VoiceVideo_logo" width="150px" height="150px" />
          <h4 className="text-center mt-4 font-weight-bold">VOICE & VIDEO</h4>
          <p className="text-center">Customizable video solutions, cloud-based phone <br />
          systems,
            and scalable SIP/PRI trunking for <br />
            seamless
            calling, business continuity <br />
            and peak demand flexibility. </p>
        </Col>
        </Row>
      
      </>

  );
}

function NeedAssistance({ location = {} }) {
  const navigate = useNavigate();
  return (
    <>
      <Row className="justify-content-center mt-5" >
        <Col xs={15} md={20}>
          <h3>Need Assistance?</h3>
          <p>Our convention center sales and support teams are standing by to answer your questions and help your

            event runs smoothly. Let us know how we can help. Reach out today to experience our personalized,

            white-glove service.</p>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginTop: '20px' }} className="mt-3">
        <Col xs={15} md={20}>
          <Button
            color="primary"
            onClick={() => navigate.toContact(location.locationID)} >
            Talk to Our Team
          </Button>
        </Col>
      </Row>
    </>
  );
}