/**
 * Combines a variable number of statuses into one.
 * @param {Array} param0 Combines a variable number of loading statuses.
 * @returns A single status created by combining many.
 */
export default function reduceStatus(...statuses) {
  return statuses.reduce(
    (prev, cur) => {
      prev.isLoading = prev.isLoading || cur.isLoading;
      if (cur.error) prev.error = cur.error;
      return prev;
    },
    { isLoading: false, error: undefined }
  );
}
