import settings from './settings.json';

let features = [];

if (
  process.env.REACT_APP_FEATURE_BLOG &&
  process.env.REACT_APP_FEATURE_BLOG.match(/on/i)
) {
  features.push('blog');
}

if (
  process.env.REACT_APP_FEATURE_VIDEOS &&
  process.env.REACT_APP_FEATURE_VIDEOS.match(/on/i)
) {
  features.push('videos');
}

const _settings = {
  ...settings,
  cmsSiteBaseUrl: process.env.REACT_APP_CMS_SITE_BASE_URL,
  enableFeatures: features,
};

export default _settings;
