import { useLayoutEffect } from 'react';

import settings from '@/settings';
const { siteTitleSuffix } = settings;

export default function useTitle(title) {
  useLayoutEffect(() => {
    if (title) {
      if (title === siteTitleSuffix) {
        document.title = title;
      } else {
        document.title = `${title} - ${siteTitleSuffix}`;
      }
    }
  }, [title]);
}
