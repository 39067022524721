import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CustomInput, FormFeedback } from 'reactstrap';

export default function SelectInput({
  name,
  placeholder,
  isNumber,
  options,
  onChange,
}) {
  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error != null;
  const { value, setValue, onChange: fieldOnChange, ...otherField } = field;

  function handleChange(e) {
    if (isNumber) {
      helpers.setValue(Number(e.target.value));
    } else {
      fieldOnChange(e);
    }

    if (onChange) {
      const match = options.find((i) => i.key === e.target.value);
      onChange(match);
    }
  }

  useEffect(() => {
    if (
      placeholder == null &&
      Array.isArray(options) &&
      options.length > 0 &&
      options[0].length > 0
    ) {
      helpers.setValue(options[0][0]);
    }
  }, [options, placeholder, helpers]);

  return (
    <>
      <CustomInput
        type="select"
        id={name}
        value={value == null ? '' : value}
        invalid={isError}
        onChange={handleChange}
        {...otherField}>
        {placeholder && (
          <option key={0} value="">
            {placeholder}
          </option>
        )}
        {options &&
          options.map(({ key, text }) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
      </CustomInput>
      <FormFeedback valid={!isError}>{meta.error}</FormFeedback>
    </>
  );
}

export function makePair(id, name) {
  return { key: id, text: name ?? id };
}

const stringOrNumberPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: stringOrNumberPropType,
  placeholder: PropTypes.string,
  isNumber: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: stringOrNumberPropType,
      text: stringOrNumberPropType,
    })
  ),
};
