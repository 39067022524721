import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { recursive as merge } from 'merge';
import { useFormik, FormikProvider, Form } from 'formik';
import isEqual from 'react-fast-compare';
import pick from 'lodash/pick';

import * as schemas from './schema';
import { httpRequest } from '@/services';
import { useAppContext } from '@/context';
import { PlaceOrderContextProvider } from './context';
import { reducer as orderStateReducer } from './provider';
import { Flow, BodyContainer, NotFound } from '@/components';
import { objectToPaths, mapServiceObjToArray } from './services';
import { useNavigate, useSessionStorage, useTitle, useLoad } from '@/hooks';

import EventInfo from './steps/EventInfo';
import BillingInfo from './steps/BillingInfo';
import SelectServices from './steps/SelectServices';
import VerifyOrder from './steps/VerifyOrder';

const Steps = {
  EventInfo: {
    stepNumber: 1,
    caption: 'Event Information',
  },
  BillingInfo: {
    stepNumber: 2,
    caption: 'Billing',
  },
  SelectServices: {
    stepNumber: 3,
    caption: 'Select Services',
  },
  VerifyOrder: {
    stepNumber: 4,
    caption: 'Verification',
  },
};

const StepMap = {
  [Steps.EventInfo.stepNumber]: {
    component: EventInfo,
    initialState: schemas.EventInfo.state,
    validationSchema: schemas.EventInfo.schema,
  },
  [Steps.BillingInfo.stepNumber]: {
    component: BillingInfo,
    initialState: schemas.BillingInfo.state,
    validationSchema: schemas.BillingInfo.schema,
  },
  [Steps.SelectServices.stepNumber]: {
    component: SelectServices,
    initialState: schemas.SelectServices.state,
    validationSchema: schemas.SelectServices.schema,
  },
  [Steps.VerifyOrder.stepNumber]: {
    component: VerifyOrder,
    initialState: schemas.VerifyOrder.state,
    validationSchema: schemas.VerifyOrder.schema,
  },
};

export default function PlaceOrder({ locationID }) {
  useTitle('Place Order');

  const navigate = useNavigate();

  let { stepNumber } = useParams();
  stepNumber = parseInt(stepNumber);

  // Use a reducer, but also persist our state to session storage.
  const defaultOrderState = { order: { event: { locationID } }, _version: 1 };
  const [placeOrderState, dispatch, resetState] = useSessionStorage(
    `App.PlaceOrder.State[lid=${locationID}]`,
    orderStateReducer,
    defaultOrderState
  );

  const { order: orderState } = placeOrderState;
  const eventID = Number(orderState.event.eventID);

  // Configure form state and component based
  // on the step number the user is currently on.
  const FormComponent = StepMap[stepNumber].component;
  const formikProps = {
    initialValues: StepMap[stepNumber].initialState,
    validationSchema: StepMap[stepNumber].validationSchema,
  };

  // If we are reloading this order page, we most likely
  // have existing state that we need to merge into the
  // initial state.
  formikProps.initialValues = merge(
    formikProps.initialValues,
    pick(orderState, objectToPaths(formikProps.initialValues))
  );

  const formik = useFormik(
    Object.assign(formikProps, {
      onSubmit: handleSubmit,
      enableReinitialize: true,
    })
  );

  const { setPhoneNumber, setShowPlaceOrder } = useAppContext();

  // Hide the 'Place Order' link when within Place Order.
  useLayoutEffect(() => {
    setShowPlaceOrder(false);
    return () => setShowPlaceOrder(true);
  }, []);

  const [location, setLocation] = useState();
  const { status: fetchStatus } = useLoad(
    () =>
      httpRequest(`api/location/${locationID}`).then((location) => {
        setLocation(location);
        if (location.phoneNumber) {
          setPhoneNumber(location.phoneNumber);
        }
      }),
    [locationID]
  );

  // TODO:
  // Keep the user from manually forcing their way forward...
  // This isn't foolproof and can be bypassed but later
  // steps should fail.
  // if (!isNavigating && stepNumber > reachedStep) {
  //   navigate.toPlaceOrder(locationID, reachedStep);
  // }

  const moveBack = useCallback(() => {
    if (stepNumber === Steps.EventInfo.stepNumber) {
      navigate.goHome();
    } else {
      navigate.toPlaceOrder(locationID, stepNumber - 1);
    }
  }, [stepNumber, locationID, navigate]);

  function moveNext() {
    if (stepNumber < 4) {
      navigate.toPlaceOrder(locationID, stepNumber + 1);
    }
  }

  function handleFlowClick(selectedItem) {
    // If we are going back one or more steps, allow it,
    // but we don't want to allow going forward.
    if (selectedItem.stepNumber < stepNumber) {
      navigate.toPlaceOrder(locationID, selectedItem.stepNumber);
    }
  }

  const updateOrderState = useCallback(
    (values) => {
      if (values === undefined) values = formik.values;
      dispatch({ type: 'updateOrder', payload: values });
    },
    [dispatch, formik.values]
  );

  const updateService = useCallback(
    (service) => {
      if (service == null) return;
      dispatch({ type: 'updateService', payload: service });
    },
    [dispatch]
  );

  // We handle the form submits here. Child components ("FormComponent")
  // that contain a button of type=submit will trigger this handler.
  function handleSubmit(values, { setSubmitting }) {
    updateOrderState(values);
    setSubmitting(false);
    moveNext();
  }

  // Create the order by posting to the server.
  const createOrder = useCallback(
    (orderTotal) => {
      return httpRequest.post(`api/order`, {
        payload: merge({
          order: {
            ...orderState,
            ...formik.values,
            services: mapServiceObjToArray(orderState.services),
          },
          orderTotal,
        }),
      });
    },
    [orderState, formik.values]
  );

  // If the order state is default, make sure we're on step 1.
  if (isEqual(placeOrderState, defaultOrderState) && stepNumber !== 1) {
    navigate.toPlaceOrder(locationID, 1);
    return null;
  }

  if (fetchStatus.error?.isNotFound) {
    return <NotFound />;
  }

  return (
    <>
      <BodyContainer
        className="bg-white shadow-inset-b-sm sticky-top-body"
        style={{ zIndex: 2 }}>
        <Flow
          className="mx-n3"
          onClick={handleFlowClick}
          items={Object.values(Steps).map((step) =>
            Flow.createFlowItem(
              step.stepNumber,
              step.caption,
              step.stepNumber === stepNumber
            )
          )}
        />
      </BodyContainer>
      <FormikProvider value={formik}>
        <PlaceOrderContextProvider
          value={{
            location,
            orderState,
            resetState,
            createOrder,
            updateService,
            updateOrderState,
            loaderStatus: fetchStatus,
            validate: formik.validateForm,
            isSubmitting: formik.isSubmitting,
            setSubmitting: formik.setSubmitting,
            setFieldValue: formik.setFieldValue,
            formValues: formik.values,
            dispatch,
            moveBack,
          }}>
          <Form noValidate>
            <FormComponent
              eventID={eventID}
              location={location}
              locationID={locationID}
            />
          </Form>
        </PlaceOrderContextProvider>
      </FormikProvider>
    </>
  );
}
