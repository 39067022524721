export const PricingTiers = {
  Advanced: 1,
  Standard: 2,
  Late: 3,
};

export const OrderTypes = {
  Standard: 1,
  ShowManagement: 2,
  Vendor: 3,
};

export const PaymentTypes = {
  CreditCard: 1,
  ShowManagement: 2,
  MasterAccount: 8
};