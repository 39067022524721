import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './NavToggler.module.scss';

export default function NavToggler(props) {
  const buttonClasses = classNames('d-sm-none', styles.toggler);

  const iconClass = classNames({
    'icon-chezburger': props.collapsed,
    'icon-close': !props.collapsed,
  });

  return (
    <button
      aria-label="Toggle navigation"
      type="button"
      className={buttonClasses}
      onClick={props.onClick}>
      <i className={iconClass} />
    </button>
  );
}

NavToggler.propTypes = {
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};
