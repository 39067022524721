import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { httpRequest } from '@/services';
import { LoadingProvider } from '@/context';
import { useLoad, useNavigate } from '@/hooks';
import { SkeletonLoader, Loader } from '@/components';

import BlogPostList from './components/BlogPostList';
import BodyContainer from './components/BodyContainer';
import SocialLinks from './components/SocialLinks';

import settings from '@/settings';
const { cmsSiteBaseUrl } = settings;

export default function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState({});

  const { status: getPostStatus } = useLoad(() => {
    return httpRequest
      .get(`${cmsSiteBaseUrl}/api/blog/posts/${slug}`)
      .then((post) => setPost(post));
  }, [slug]);

  const morePosts = post.morePosts?.slice(0, 2) || [];

  return (
    <LoadingProvider {...getPostStatus}>
      <Loader>
        <BodyContainer className="mt-5">
          <Row>
            <ContentContainer>
              <BlogPostHeader post={post} />
            </ContentContainer>
          </Row>
          <Row>
            <Col className="my-4 text-center">
              <SkeletonLoader type="image" width="100%" height="200px">
                {() => (
                  <img
                    className="img-fluid"
                    src={post.content?.image?.fullSize}
                    title={post.content?.title}
                    alt={post.content?.title}
                  />
                )}
              </SkeletonLoader>
            </Col>
          </Row>
          <ContentContainer>
            <Row>
              <Col xs="auto">
                <SocialLinks className="my-3 my-lg-0 ml-lg-n6 position-lg-absolute" />
              </Col>
            </Row>
            <SkeletonLoader count={10}>
              {() =>
                post.content?.parts &&
                post.content.parts.map((part, i) => (
                  <PartRenderer key={i} {...part} />
                ))
              }
            </SkeletonLoader>
            <Row>
              <Col className="mt-5">
                <Navigation post={post} />
              </Col>
            </Row>
          </ContentContainer>
          <Row>
            <Col className="mt-6 mx-lg-7">
              <h4>Read more</h4>
              <BlogPostList className="mt-4" posts={morePosts} />
            </Col>
          </Row>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}

function ContentContainer({ children }) {
  return (
    <Col xs="18" lg="12" className="mx-auto">
      {children}
    </Col>
  );
}

function PartRenderer({ type, ...props }) {
  switch (type) {
    case 'Paragraph': {
      return <ParagraphRenderer {...props} />;
    }
    case 'Image': {
      props = { ...props, className: classNames('mx-lg-n6', props.className) };
      return <ImageRenderer {...props} />;
    }
    case 'Blockquote': {
      return <BlockquoteRenderer {...props} />;
    }
    default:
      return null;
  }
}

PartRenderer.propTypes = {
  type: PropTypes.string.isRequired,
};

function ParagraphRenderer({ html }) {
  return (
    <p
      className="line-height-md"
      dangerouslySetInnerHTML={{ __html: html.replace(/<\/?p>/g, '') }}
    />
  );
}

function ImageRenderer({ href, caption, alt }) {
  return (
    <div className="my-4 text-left text-md-center">
      <img className="img-fluid" src={href} title={alt} alt={alt} />
      {caption && (
        <div className="mt-3 color-gray-700">
          <em>{caption}</em>
        </div>
      )}
    </div>
  );
}

function BlockquoteRenderer({ quote }) {
  return (
    <blockquote className="my-4 blockquote text-center color-primary text-size-xxl">
      {quote}
    </blockquote>
  );
}

function Navigation({ post }) {
  const { getBlogUrl } = useNavigate();

  return (
    <div className="d-flex justify-content-between justify-content-md-center">
      <PostPagerLink className="mr-5" post={post.previousPost}>
        <span className="icon-arrow-left"></span> Prev
      </PostPagerLink>
      <Link to={getBlogUrl()}>All Blogs</Link>
      <PostPagerLink className="ml-5" post={post.nextPost}>
        Next <span className="icon-arrow-right"></span>
      </PostPagerLink>
    </div>
  );
}

function PostPagerLink({ post, children, className }) {
  const { getBlogPostUrl } = useNavigate();

  return (
    <>
      {post != null ? (
        <Link
          className={classNames(className, 'text-decoration-none color-dark')}
          to={getBlogPostUrl(post.slug)}
          title={`Go to ${post.title}`}>
          {children}
        </Link>
      ) : (
        <div className={classNames(className, 'disabled')}>{children}</div>
      )}
    </>
  );
}

function BlogPostHeader({ post }) {
  const { getBlogUrl } = useNavigate();

  return (
    <Row className="justify-content-center align-items-center">
      <Col className="text-center">
        <SkeletonLoader tag="div" width="25%">
          {() => (
            <Link
              className="text-decoration-none color-info text-size-sm text-bold"
              to={getBlogUrl({
                category: post.content?.category,
              })}>
              {post.content?.category}
            </Link>
          )}
        </SkeletonLoader>
        <SkeletonLoader tag="h1" width="100%" className="text-medium">
          {() => post.content?.title}
        </SkeletonLoader>
      </Col>
    </Row>
  );
}
