import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useNavigate } from '@/hooks';
import { SkeletonLoader } from '@/components';
import { useLoadingContext } from '@/context';

import { postSummaryShape } from '../models';

export default function BlogPostList({ posts, className }) {
  const { isLoading } = useLoadingContext();

  return (
    <div className={classNames(className, 'd-flex flex-column')}>
      {!isLoading && posts.length === 0 ? (
        <div className="text-center">There are no more posts to display.</div>
      ) : (
        posts.map((post) => (
          <BlogPostListItem key={post.id} className="mb-5" post={post} />
        ))
      )}
    </div>
  );
}

function BlogPostListItem({ post, className }) {
  const { getBlogUrl, getBlogPostUrl } = useNavigate();

  return (
    <Row className={className}>
      {/* Image */}
      <Col xs="18" sm="6" className="d-flex flex-column justify-content-center">
        <SkeletonLoader type="image" width="100%" height="150px">
          {() => (
            <Link to={getBlogPostUrl(post.slug)}>
              {post.image && (
                <img
                  className="img-fluid"
                  src={post.image?.preview}
                  title={post.title}
                  alt={post.title}
                />
              )}
            </Link>
          )}
        </SkeletonLoader>
      </Col>
      <Col
        xs="18"
        sm="12"
        className="d-flex flex-column justify-content-center mt-3 mt-md-0">
        {/* Category */}
        <SkeletonLoader tag="div" width="20%">
          <Link
            className="text-decoration-none color-info text-bold"
            to={getBlogUrl({ category: post.category })}>
            {post.category}
          </Link>
        </SkeletonLoader>
        {/* Title */}
        <SkeletonLoader tag="div" width="100%" className="text-md-truncate">
          <Link
            className="text-decoration-none color-primary text-medium text-size-xl"
            to={getBlogPostUrl(post.slug)}>
            {post?.title}
          </Link>
        </SkeletonLoader>
        {/* Summary text */}
        <SkeletonLoader
          tag="div"
          width="100%"
          className="d-none d-md-block mt-2">
          {post?.summary}
        </SkeletonLoader>
      </Col>
    </Row>
  );
}

BlogPostList.propTypes = {
  posts: PropTypes.arrayOf(postSummaryShape),
};

BlogPostListItem.propTypes = {
  post: postSummaryShape,
};
