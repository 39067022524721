import React, { useState, useRef } from 'react';
import { recursive as merge } from 'merge';
import { useParams } from 'react-router-dom';

import { format, httpRequest } from '@/services';
import { useLoad, useNavigate, useTitle, useToggle } from '@/hooks';
import { LoadingProvider, OrderTypes, PaymentTypes } from '@/context';

import LineItems from './components/LineItems';
import SignatureForm from './components/SignatureForm';
import TransactionsList from './components/TransactionsList';
import WifiAccessCodesList from './components/WifiAccessCodesList';

import { Row, Col, Label, Collapse, FormGroup } from 'reactstrap';

import {
  Hero,
  Brand,
  Terms,
  Loader,
  Button,
  Alert,
  FlexTable,
  FormHeader,
  FormSection,
  BodyContainer,
  TableContentLoader,
  SkeletonLoader,
  TermsModal,
} from '@/components';

export default function OrderDetails() {
  const navigate = useNavigate();
  const { confirmationNumber } = useParams();

  const [showTerms, toggleShowTerms] = useToggle(false);
  const [showTotals, toggleShowTotals] = useToggle(false);

  const [title, setTitle] = useState();
  const [orderDetails, setOrderDetails] = useState({});

  const { status: loadStatus } = useLoad(() =>
    httpRequest.get(`api/order/conf/${confirmationNumber}`).then((data) => {
      setOrderDetails(data);
      setTitle(data.order.orderStatusTypeID === 4 || data.order.orderStatusTypeID === 5 ? 'Closed Order' : data.order.customerDidSign ? 'Order Invoice' : 'Order Quote');
    })
  );

  useTitle(title);

  const signatureRef = useRef();
  function handleSigned({ signature }) {
    setOrderDetails((state) =>
      merge(true, state, {
        order: {
          customerDidSign: true,
          customerSignature: signature,
          customerSignedDate: new Date(),
        },
      })
    );
  }

  function handlePay() {
    navigate.gotoUrl(orderDetails.paymentGatewayUrl);
  }

  const orderState = orderDetails?.order ?? {};

  const {
    onsiteContact,
    billingContact,
    services,
    transactions,
    customerNotes,
    wifiAccessCodes,
  } = orderState;

  const termsAndConditions = orderState?.termsAndConditions;
  const billingAddress = billingContact?.address;
  const companyAddress = orderState.companyAddress ?? billingAddress;
  const { isLoading } = loadStatus;
  //console.log("STATE", orderState.termsAndConditions);
  const showAccessCodes =
    wifiAccessCodes?.length > 0 &&
    (orderState.orderBalance <= 0 ||
      (orderState.orderTypeID === OrderTypes.ShowManagement &&
        orderState.customerDidSign));
  // Determine if the order was cancelled or archived.
  const isClosedOrder = orderState.orderStatusTypeID === 4 || orderState.orderStatusTypeID === 5;

  // Determine if the user is able to pay at this point
  const canPay =
    orderState.paymentTypeID !== PaymentTypes.MasterAccount &&
    orderState.customerDidSign &&
    orderState.orderBalance > 0;
  return (
    <LoadingProvider {...loadStatus}>
      <Loader>
        <Hero className="shadow-sm px-4">
          <Brand className="d-none d-print-inline-block mb-3" />
          <h2 className="text-medium">
            <SkeletonLoader type="text" width={200}>
              {title}
            </SkeletonLoader>
          </h2>
          <SkeletonLoader type="text" width={300}>
            {isClosedOrder && (<p className="d-print-none">
              Your order details are below. This order is closed. It is not an invoice nor quote, and has no outstanding
              balance.
            </p>)}
            {!isClosedOrder && (<p className="d-print-none">
              Your order details are below. If your order has an outstanding
              balance, please click the Pay button below.
            </p>)}
          </SkeletonLoader>
        </Hero>
        <BodyContainer>
          {!isLoading && !isClosedOrder && !orderState.customerDidSign && (
            <Alert type="info" className="d-print-none">
              Your order is pending a signature.{' '}
              <Button
                color="link"
                className="btn-inline"
                onClick={() =>
                  signatureRef.current.scrollIntoView({
                    behavior: 'smooth',
                  })
                }>
                Click here to sign.
              </Button>
            </Alert>
          )}
          {!isLoading && !isClosedOrder && canPay && (
            <Alert type="info" className="d-print-none">
              Your order has an outstanding balance of{' '}
              {format.currency(orderState.orderBalance)}.{' '}
              <Button color="link" className="btn-inline" onClick={handlePay}>
                Click here to pay.
              </Button>
            </Alert>
          )}
          {!isLoading && isClosedOrder && orderState.cancelOrderDate !== null && (
            <Alert type="info" className="d-print-none">
              This order was closed on{' '}
              {new Date(orderState.cancelOrderDate).toLocaleString()}.
            </Alert>
          )}
          <FormSection
            title="Order Information"
            titleClass="bg-gray-700 text-white">
            <Row form>
              <Col>
                <FormGroup>
                  <Label>Order Confirmation #</Label>
                  <div className="text-nowrap">
                    <SkeletonLoader>
                      {() => orderState.confirmationNumber}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={18} sm={9}>
                <FormGroup>
                  <Label>Order Date</Label>
                  <div>
                    <SkeletonLoader>
                      {() => new Date(orderState.orderDate).toLocaleString()}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label>Company PO/MA#</Label>
                <div>
                  <SkeletonLoader>
                    {() => orderState.purchaseOrderNumber || '(Not provided)'}
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection
            title="Event Information"
            titleClass="bg-gray-700 text-white">
            <Row>
              <FormGroup>
                <Label>Location</Label>
                <div>
                  <SkeletonLoader>
                    {() => orderState.locationName}
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label>Event</Label>
                <div>
                  <SkeletonLoader>{() => orderState.eventName}</SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row form>
              <Col xs={18} sm={9}>
                <FormGroup>
                  <Label>Booth/Room #</Label>
                  <SkeletonLoader tag="div">
                    {() => orderState.boothNumber}
                  </SkeletonLoader>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Booth Type</Label>
                  <SkeletonLoader tag="div">
                    {() => orderState.boothType}
                  </SkeletonLoader>
                </FormGroup>
              </Col>
            </Row>
          </FormSection>
          <FormSection
            title="Exhibitor Information"
            titleClass="bg-gray-700 text-white">
            <Row>
              <FormHeader title="Company Information" />
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label>Name</Label>
                  <div>
                    <SkeletonLoader>
                      {() => orderState.companyName}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={18} sm={9}>
                <FormGroup>
                  <Label>Address</Label>
                  <div>
                    <SkeletonLoader>
                      {() =>
                        companyAddress
                          ? format.address(companyAddress, {
                              multiline: true,
                              showCountry: true,
                            })
                          : '(Not available)'
                      }
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormHeader title="On-Site Contact" />
            </Row>
            <Row>
              <FormGroup>
                <Label>Name</Label>
                <div>
                  <SkeletonLoader>
                    {() =>
                      `${onsiteContact.firstName} ${onsiteContact.lastName}`
                    }
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label>Phone</Label>
                <div>
                  <SkeletonLoader>
                    {() => format.phoneNumber(onsiteContact.phone)}
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label>Email</Label>
                <div>
                  <SkeletonLoader>{() => onsiteContact.email}</SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row className="mt-3">
              <FormHeader title="Billing Contact" />
            </Row>
            <Row>
              <FormGroup>
                <Label>Name</Label>
                <div>
                  <SkeletonLoader>
                    {() =>
                      `${billingContact.firstName} ${billingContact.lastName}`
                    }
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label>Phone</Label>
                  <div>
                    <SkeletonLoader>
                      {() => format.phoneNumber(billingContact.phone)}
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={18} sm={9}>
                <FormGroup>
                  <Label>Fax</Label>
                  <div>
                    <SkeletonLoader>
                      {() =>
                        billingContact.fax
                          ? format.phoneNumber(billingContact.fax)
                          : '(None provided)'
                      }
                    </SkeletonLoader>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label>Email</Label>
                <div>
                  <SkeletonLoader>{() => billingContact.email}</SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label>Address</Label>
                <div>
                  <SkeletonLoader>
                    {() =>
                      billingAddress
                        ? format.address(billingAddress, {
                            multiline: true,
                            showCountry: true,
                          })
                        : '(Not available)'
                    }
                  </SkeletonLoader>
                </div>
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection title="Services" titleClass="bg-gray-700 text-white">
            <TableContentLoader show={isLoading}>
              <LineItems className="my-2 py-2" services={services} />
              <Button
                color="link"
                className="pl-0 d-print-none"
                onClick={toggleShowTotals}>
                {showTotals ? 'Hide' : 'Show'} Discount, Tax &amp; Fee Totals
              </Button>
              <Collapse
                isOpen={showTotals}
                className="text-size-sm d-print-block">
                <div className="d-flex">
                  <div className="mr-auto">Total Advanced Discount</div>
                  <div>{format.currency(orderState.totalEarlyDiscount)}</div>
                </div>
                <div className="d-flex">
                  <div className="mr-auto">Total Expedite Fees</div>
                  <div>{format.currency(orderState.totalExpediteFees)}</div>
                </div>
                {orderState.orderTypeID === OrderTypes.ShowManagement && (
                  <div className="d-flex">
                    <div className="mr-auto">
                      Total Show Management Discount
                    </div>
                    <div>
                      {format.currency(orderState.totalShowMgmtDiscount)}
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="mr-auto">Total Additional Discounts</div>
                  <div>{format.currency(orderState.totalDiscounts)}</div>
                </div>
                <div className="d-flex">
                  <div className="mr-auto">Total Tax</div>
                  <div>{format.currency(orderState.totalTaxes)}</div>
                </div>
                <div className="d-flex">
                  <div className="mr-auto">Total Fees</div>
                  <div>{format.currency(orderState.totalFees)}</div>
                </div>
              </Collapse>
              <div className="d-flex bt-double mt-3 pt-1">
                <div className="text-bold mr-auto">Grand Total</div>
                <div>{format.currency(orderState.grandTotal)}</div>
              </div>
              {orderState.depositAmount && (
                <div className="d-flex">
                  <div className="text-bold mr-auto">Deposits</div>
                  <div>{format.currency(orderState.depositAmount)}</div>
                </div>
              )}
              <div className="d-flex">
                <div className="text-bold mr-auto">Total Refunds</div>
                <div>{format.currency(orderState.totalRefunds)}</div>
              </div>
              <div className="d-flex">
                <div className="text-bold mr-auto">Total Payments</div>
                <div>{format.currency(orderState.totalCharges)}</div>
              </div>
              <div className="d-flex">
                <div className="text-bold mr-auto">Remaining Balance</div>
                <div>
                  <strong>{format.currency(orderState.orderBalance)}</strong>
                </div>
              </div>
              {!isClosedOrder && canPay && (
                <>
                  <div className="mt-4">
                    <Button
                      block
                      type="button"
                      color="primary"
                      className="mb-4 d-print-none"
                      onClick={handlePay}>
                      Pay balance
                    </Button>
                  </div>
                  <div className="text-size-sm">
                    {orderState.orderTypeID === OrderTypes.ShowManagement && (
                      <p>
                        Balance due NET 30 after last day of show. Due by{' '}
                        {format.dateTime(orderState.net30DueDate)}.
                      </p>
                    )}
                    <p>
                      Checks must be received at least 30 days prior to the
                      listed first day of the event. <br />
                      Make checks payable to: Cox Business.
                    </p>
                    <div>
                      Mail payments to:{' '}
                      <address>
                        ATTN: Advanced Convention Services, Cox Business
                        <br />
                        1700 Vegas Drive
                        <br />
                        Las Vegas, NV 89106
                      </address>
                    </div>
                  </div>
                </>
              )}
            </TableContentLoader>
          </FormSection>
          {orderState.orderTypeID === OrderTypes.Standard && (
            <FormSection
              title="Transactions"
              titleClass="bg-gray-700 text-white">
              <TransactionsList
                isLoading={isLoading}
                transactions={transactions}
              />
            </FormSection>
          )}
          {customerNotes && customerNotes.length > 0 && (
            <FormSection
              title="Customer Notes"
              titleClass="bg-gray-700 text-white">
              <TableContentLoader show={isLoading}>
                <FlexTable
                  striped
                  secondaryClass="bg-light"
                  className="mx-n3 mt-n3">
                  <FlexTable.Head>
                    <FlexTable.Cell>Date</FlexTable.Cell>
                    <FlexTable.Cell>Message</FlexTable.Cell>
                  </FlexTable.Head>
                  <FlexTable.Body>
                    {customerNotes.map((note, idx) => (
                      <FlexTable.Row key={idx}>
                        <FlexTable.Cell noHeader>
                          <div className="text-size-md text-bold">
                            {new Date(note.dateTime).toLocaleString()}
                          </div>
                        </FlexTable.Cell>
                        <FlexTable.Cell noHeader>{note.message}</FlexTable.Cell>
                      </FlexTable.Row>
                    ))}
                  </FlexTable.Body>
                </FlexTable>
              </TableContentLoader>
            </FormSection>
          )}
          {!isClosedOrder && showAccessCodes && (
            <FormSection
              title="Wi-Fi Access Codes"
              titleClass="bg-gray-700 text-white">
              <WifiAccessCodesList
                isLoading={isLoading}
                wifiAccessCodes={wifiAccessCodes}
              />
            </FormSection>
          )}
          {!isClosedOrder && orderState.orderTypeID !== PaymentTypes.MasterAccount && (
            <FormSection
              ref={signatureRef}
              title="Customer Signature"
              titleClass="bg-gray-700 text-white"
              className="mb-5 d-print-none">
              {orderState.customerDidSign ? (
                <>
                  <strong>
                    This order was signed by{' '}
                    <strong>{orderState.customerSignature}</strong> on{' '}
                    {new Date(
                      orderState.customerSignedDate
                    ).toLocaleDateString()}
                    .{' '}
                    <span className="d-print-none">
                      View the{' '}
                      <Button color="inline" onClick={toggleShowTerms}>
                        Terms and Conditions
                      </Button>
                      .
                    </span>
                  </strong>
                  <TermsModal
                    showTerms={showTerms}
                    toggleShowTerms={toggleShowTerms}
                    message={termsAndConditions}
                  />
                </>
              ) : (
                <SignatureForm
                  confirmationNumber={confirmationNumber}
                  onSigned={handleSigned}
                  message={termsAndConditions}
                />
              )}
            </FormSection>
          )}
          <div className="d-none d-print-block mt-5">
            <h2 className="mb-4">Terms and Conditions</h2>
            <hr />
            <Terms message={termsAndConditions} />
          </div>
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}
