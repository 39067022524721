import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import * as Yup from 'yup';
import merge from 'merge';

import { getDefaultState, getValidationSchema } from '@/services/Schema';
import settings from '@/settings';

export const intlPhonePattern = /^\+?[\d -]{9,15}$/gi;

const acceptedFileNames = uniq(
    settings.acceptedFileTypes.map((v) => v.name)
).join(', ');

const acceptedFileTypes = settings.acceptedFileTypes.map((v) => v.type);

const eventInfoSchema = {
    event: {
        eventID: {
            default: 0,
            validation: Yup.number()
                .moreThan(0, 'An event is required')
                .required('An event is required'),
        },
        hallID: {
            default: 0,
            validation: Yup.number()
                .moreThan(0, 'A hall is required')
                .required('A hall is required'),
        },
        boothNumber: {
            default: '',
            validation: Yup.string()
                .max(100, 'Must be a maximum of ${max} characters')
                .required('A booth/room/sign # is required'),
        },
        boothLayoutFile: {
            default: null,
            validation: Yup.object()
                .shape({
                    name: Yup.string(),
                    size: Yup.number()
                        .integer()
                        .max(2_097_152, 'The file size is larger than what is allowed'),
                    type: Yup.mixed().oneOf(
                        acceptedFileTypes,
                        `The file must be one of the following types: ${acceptedFileNames}`
                    ),
                    objectURL: Yup.string(),
                })
                .nullable(),
        },
    },
    onsiteContact: {
        firstName: {
            default: '',
            validation: Yup.string()
                .max(50, 'Must be a maximum of ${max} characters')
                .required('A first name is required'),
        },
        lastName: {
            default: '',
            validation: Yup.string()
                .max(50, 'Must be a maximum of ${max} characters')
                .required('A last name is required'),
        },
        phone: {
            default: '',
            validation: Yup.string()
                .max(50, 'Must be a maximum of ${max} characters')
                .matches(intlPhonePattern, 'The phone number is not valid')
                .required('A phone number is required'),
        },
        email: {
            default: '',
            validation: Yup.string()
                .max(200, 'Must be a maximum of ${max} characters')
                .email('The email address is not valid')
                .required('An email address is required'),
        },
    },
};

export const EventInfo = {
    state: getDefaultState(eventInfoSchema),
    schema: getValidationSchema(eventInfoSchema),
};

const addressDefaultState = {
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: 'US',
};

const billingInfoDefaultState = {
    billingContact: {
        companyName: '',
        firstName: '',
        lastName: '',
        phone: '',
        fax: '',
        email: '',
        address: merge(true, addressDefaultState),
    },
    companyAddress: merge(true, addressDefaultState, {
        isBillingAddress: true,
    }),
};

const addressValidation = Yup.object({
    address1: Yup.string()
        .max(100, 'Must be a maximum of ${max} characters')
        .required('An address is required'),
    address2: Yup.string()
        .max(100, 'Must be a maximum of ${max} characters')
        .notRequired(),
    city: Yup.string()
        .max(100, 'Must be a maximum of ${max} characters')
        .required('A city is required'),
    stateCode: Yup.string()
        .max(7, 'Must be a maximum of ${max} characters')
        .required('A state is required'),
    postalCode: Yup.string()
        .uppercase()
        .matches(/^[(a-zA-Z)\d-]{1,10}$/, 'The postal code is not valid')
        .required('A postal code is required'),
    countryCode: Yup.string()
        .default(addressDefaultState.countryCode)
        .required('A country is required'),
});

const billingContactValidation = Yup.object({
    companyName: Yup.string()
        .max(100, 'Must be a maximum of ${max} characters')
        .required('A company name is required'),
    firstName: Yup.string()
        .max(50, 'Must be a maximum of ${max} characters')
        .required('A first name is required'),
    lastName: Yup.string()
        .max(50, 'Must be a maximum of ${max} characters')
        .required('A last name is required'),
    phone: Yup.string()
        .max(50, 'Must be a maximum of ${max} characters')
        .matches(intlPhonePattern, 'The phone number is not valid')
        .required('A phone number is required'),
    fax: Yup.string()
        .max(50, 'Must be a maximum of ${max} characters')
        .matches(intlPhonePattern, 'The fax number is not valid')
        .notRequired(),
    email: Yup.string()
        .max(200, 'Must be a maximum of ${max} characters')
        .email('The email address is not valid')
        .required('An email address is required'),
});

const billingInfoValidation = Yup.object({
    billingContact: billingContactValidation
        .concat(Yup.object({ address: addressValidation }))
        .when('companyAddress.isBillingAddress', function (value, schema) {
            return value ? billingContactValidation : schema;
        }),
    companyAddress: merge(true, addressValidation, {
        isBillingAddress: Yup.boolean().default(true),
    }),
});

export const BillingInfo = {
    state: billingInfoDefaultState,
    schema: billingInfoValidation,
};

const selectServicesDefaultState = {
    services: {},
};

const selectServicesValidation = Yup.object({
    services: Yup.lazy((obj) =>
        Yup.object(
            mapValues(obj, () =>
                Yup.object({
                    quantity: Yup.number(),
                })
            )
        ).test(
            'min-services',
            'Please select at least one service to continue.',
            (value) => Object.values(value).some((val) => val.quantity > 0)
        )
    ),
});

export const SelectServices = {
    state: selectServicesDefaultState,
    schema: selectServicesValidation,
};

const verifyOrderSchema = {
    verification: {
        acceptTerms: {
            default: false,
            validation: Yup.boolean()
                .oneOf([true], 'You must agree to the Terms and Conditions')
                .required('You must agree to the Terms and Conditions'),
        },
        subscribeToNewsletter: {
            default: false,
            validation: Yup.boolean(),
        },
    },
};

export const VerifyOrder = {
    state: getDefaultState(verifyOrderSchema),
    schema: getValidationSchema(verifyOrderSchema),
};
