import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, CustomInput } from 'reactstrap';
import { reduceStatus, httpRequest } from '@/services';
import { useLoad, useTitle, useNavigate } from '@/hooks';
import { LoadingProvider, useLoadingContext } from '@/context';
import { Pager, Loader, SkeletonLoader, Skeleton } from '@/components';

import BodyContainer from './components/BodyContainer';
import FeaturedPost from './components/FeaturedPost';
import BlogPostList from './components/BlogPostList';
import { categoryShape } from './models';

import settings from '@/settings.js';
const { cmsSiteBaseUrl, blogPostsPerPage } = settings;

export default function Blog() {
  let { category, pageNumber = 1 } = useParams();
  category = category?.toLowerCase();

  const { getBlogUrl, toBlog } = useNavigate();
  const [postData, setPostData] = useState({});
  const [categories, setCategories] = useState([]);
  const [featuredPost, setFeaturedPost] = useState();

  // Match category to the one requested.
  let currentCategory = categories?.find(
    ({ name }) => name.toLowerCase() === category
  );

  let pageTitle = 'Blog';
  if (currentCategory) {
    pageTitle += ` - ${currentCategory.name}`;
  }

  useTitle(pageTitle);

  const { status: getPostsStatus } = useLoad(
    () =>
      httpRequest
        .get(`${cmsSiteBaseUrl}/api/blog/posts`, {
          payload: {
            category,
            pageNumber,
            pageSize: blogPostsPerPage,
          },
        })
        .then((data) => {
          if (data != null) {
            if (featuredPost == null && data.content?.length > 0) {
              setFeaturedPost(data.content[0]);
              data = { ...data, content: data.content?.slice(1) };
            }
            setPostData(data);
          }
        }),
    [category, pageNumber]
  );

  const { status: getCategoriesStatus } = useLoad(() =>
    httpRequest
      .get(`${cmsSiteBaseUrl}/api/blog/categories`)
      .then((cats) => setCategories(cats))
  );

  function handleCategoryChange(category) {
    toBlog({ category });
  }

  function handlePageChange(pageNumber) {
    toBlog({ pageNumber, category });
  }

  // Get posts if any.
  const blogPosts = postData?.content || [];

  // Since we only want to display categories that
  // actually have posts (instead of sending the user
  // to a "no posts found" page), filter them here.
  const categoriesWithContent = categories.filter(
    (category) => category.count > 0
  );

  const totalPages = Math.ceil(postData.totalCount / blogPostsPerPage);
  const loadingStatus = reduceStatus(getPostsStatus, getCategoriesStatus);
  const { isLoading } = loadingStatus;

  return (
    <LoadingProvider {...loadingStatus}>
      <Loader>
        <Row>
          <Col>
            <FeaturedPost post={featuredPost} />
          </Col>
        </Row>
        <BodyContainer>
          {!isLoading && blogPosts.length === 0 ? (
            <div className="mt-5 text-center">
              <p>There are no posts to display.</p>
              <p>
                <Link to={getBlogUrl()}>Return to the main blog.</Link>
              </p>
            </div>
          ) : (
            <>
              <Row>
                <Col>
                  <CategorySelect
                    className="d-lg-none"
                    categories={categoriesWithContent}
                    selected={category}
                    onChange={handleCategoryChange}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="18" lg="13" xl="14">
                  <BlogPostList posts={blogPosts} />
                </Col>
                <Col lg="5" xl="4">
                  <CategoryList
                    className="d-none d-lg-block"
                    categories={categoriesWithContent}
                    selected={category}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Pager
                    className="mt-5"
                    onChange={handlePageChange}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                  />
                </Col>
              </Row>
            </>
          )}
        </BodyContainer>
      </Loader>
    </LoadingProvider>
  );
}

function CategorySelect({ categories, selected, onChange, className }) {
  function handleChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <SkeletonLoader height="2em" width="100%" className={className}>
      {() => (
        <CustomInput
          type="select"
          id="selectedCategory"
          className={classNames(className, 'color-primary')}
          value={selected}
          onChange={handleChange}>
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name.toLowerCase()}>
              {category.name} ({category.count})
            </option>
          ))}
        </CustomInput>
      )}
    </SkeletonLoader>
  );
}

CategorySelect.propTypes = {
  categories: PropTypes.arrayOf(categoryShape),
};

function CategoryList({ categories, selected, className }) {
  const { isLoading } = useLoadingContext();
  const { getBlogUrl } = useNavigate();

  const isSelected = (category) => category.name.toLowerCase() === selected;

  return (
    <div className={classNames(className, 'bg-light p-4')}>
      <h6 className="text-bold">Categories</h6>
      <div className="mb-2">
        <Link
          className={classNames(
            'text-decoration-none text-size-sm color-dark',
            {
              'text-medium': selected == null,
            }
          )}
          to={getBlogUrl()}>
          All Categories
        </Link>
      </div>
      {isLoading ? (
        <Skeleton className="mb-2" count={4} />
      ) : (
        categories.map((category) => (
          <div key={category.id} className="mb-2">
            <Link
              className={classNames(
                'text-decoration-none text-size-sm color-dark',
                {
                  'text-medium': isSelected(category),
                }
              )}
              to={getBlogUrl({ category: category.name })}>
              {category.name} ({category.count})
            </Link>
          </div>
        ))
      )}
    </div>
  );
}

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(categoryShape),
};
