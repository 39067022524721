import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

import styles from './FormSection.module.scss';

const FormSection = forwardRef(function (props, ref) {
  const {
    title,
    titleClass,
    color,
    style,
    className,
    children,
    ...otherProps
  } = props;

  const titleClasses = classNames(
    styles['section-title'],
    styles['legend'],
    'text-truncate',
    titleClass
  );

  return (
    <fieldset
      ref={ref}
      className={classNames(styles.container, className)}
      {...otherProps}>
      <legend className={titleClasses} style={style} title={title}>
        {title}
      </legend>
      <Row>
        <Col>{children}</Col>
      </Row>
    </fieldset>
  );
});

export default FormSection;

FormSection.propTypes = {
  title: PropTypes.node.isRequired,
  titleClass: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'gray-700']),
  children: PropTypes.node,
};
