import React from 'react';

const optionDefaults = {
  lineBreak: (i) => <br key={i} />,
  multiline: false,
  showCountry: false,
};

const formatAddress = (address, options) => {
  if (address == null) return '';

  options = Object.assign({}, optionDefaults, options);
  const { lineBreak, multiline, showCountry } = options;

  const {
    address1,
    address2,
    city,
    state,
    stateCode,
    postalCode,
    country,
    countryCode,
  } = address;

  // Make sure all the required parts of the address exist.
  if (
    city == null ||
    address1 == null ||
    (state == null && stateCode == null)
  ) {
    return null;
  }

  // Build the address segments.
  const streetSegment = `${address1}${address2 ? `, ${address2}` : ''}`;
  const cityStateZipSegment = `${city}, ${state ?? stateCode.substring(3)}${
    postalCode ? ` ${postalCode}` : ''
  }`;
  const countrySegment = country ?? countryCode;

  let idx = 0;
  const createLineBreak = () => {
    return typeof lineBreak === 'function' ? lineBreak(++idx) : lineBreak;
  };

  if (multiline === true) {
    const ret = [streetSegment, createLineBreak(), cityStateZipSegment];
    if (showCountry) {
      ret.push([createLineBreak(), countrySegment]);
    }
    return ret;
  } else {
    return `${streetSegment}, ${cityStateZipSegment}${
      showCountry && countrySegment ? ', ' + countrySegment : ''
    }`;
  }
};

export default formatAddress;
