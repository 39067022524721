import produce from 'immer';
import omit from 'lodash/omit';
import { recursive as merge } from 'merge';

export function reducer(state, action) {
  switch (action.type) {
    case 'updateOrder':
      return produce(state, (draft) => {
        let { payload: newState } = action;
        newState = omit(newState, 'order.event.locationID');

        merge(draft, { order: { ...newState } });
        const { order: orderState } = draft;

        if (
          orderState.companyAddress &&
          orderState.companyAddress.isBillingAddress &&
          orderState.billingContact &&
          orderState.billingContact.address
        ) {
          // If the company address is the billing address,
          // make sure the separate billing address gets removed.
          delete orderState.billingContact.address;
        }

        // If services, overwrite.
        if (newState.services) {
          orderState.services = newState.services;
        }
      });
    case 'updateService':
      return produce(state, (draft) => {
        merge(draft, { order: { services: action.payload } });
      });
    case 'reset':
      return action.payload;
    default:
      break;
  }
}
