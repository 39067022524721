const cookies = {};

cookies.get = function (name) {
  const key = name + '=';
  const allCookies = decodeURIComponent(document.cookie);
  const cookies = allCookies.split(';');
  for (let part of cookies) {
    while (part.charAt(0) === ' ') {
      part = part.substring(1);
    }
    if (part.indexOf(key) === 0) {
      return part.substring(key.length, part.length);
    }
  }
  return '';
};

export default cookies;
