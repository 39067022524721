/**
 * getDefaultState
 * @param {Object} schema Gets the default state from the provided mixed schema.
 */
export default function getDefaultState(schema) {
  if (schema == null) return {};

  // State is array
  if (Array.isArray(schema)) {
    return [getDefaultState(schema[0])];
  }

  // State is object
  let ret = {};
  for (const prop in schema) {
    if (prop !== 'validation') {
      if (schema[prop] != null) {
        if (typeof schema[prop] === 'object' && !Array.isArray(schema[prop])) {
          if (schema[prop]['default'] !== undefined) {
            ret[prop] = schema[prop]['default'];
            continue;
          }
        }
        ret[prop] = getDefaultState(schema[prop]);
        continue;
      }

      // Not an object or array, but a leaf,
      // so use whatever value it's assigned.
      ret[prop] = schema[prop];
    }
  }
  return ret;
}
