import React, { createContext, useState, useContext } from 'react';
import merge from 'merge';

import settings from '@/settings';
const { defaultPhoneNumber } = settings;

const initialValue = {
  baseUrl: '/',
  rootElement: null,
  navCollapsed: true,
  showPlaceOrder: true,
  phoneNumber: defaultPhoneNumber,
  setPhoneNumber: () => {},
  setNavCollapsed: () => {},
  setShowPlaceOrder: () => {},
};

const AppContext = createContext(initialValue);

export function AppContextProvider({ value, children }) {
  const [phoneNumber, setPhoneNumber] = useState(initialValue.phoneNumber);
  const [navCollapsed, setNavCollapsed] = useState(initialValue.navCollapsed);
  const [showPlaceOrder, setShowPlaceOrder] = useState(
    initialValue.showPlaceOrder
  );

  const contextValue = merge(true, initialValue, {
    ...value,
    phoneNumber,
    setPhoneNumber,
    navCollapsed,
    setNavCollapsed,
    showPlaceOrder,
    setShowPlaceOrder,
  });

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
