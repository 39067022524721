import React from 'react';
import PropTypes from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';

import { useLoadingContext } from '@/context/LoadingContext';
import { default as InnerSkeleton } from 'react-loading-skeleton';

export default function Skeleton({
  tag: Tag,
  type = 'text',
  height,
  width,
  style,
  render,
  ...otherProps
}) {
  if (render && typeof render === 'function') {
    return render(otherProps);
  }

  const defaults = {
    width,
    height,
  };

  // Adjust defaults based on type.
  switch (type) {
    case 'button':
      defaults.height = 40;
      defaults.width = 150;
      break;
    case 'image':
      defaults.height = '100%';
      break;
    default:
    // Noop
  }

  const innerProps = {
    height: height || defaults.height,
    width: width || defaults.width,
  };

  return <InnerSkeleton wrapper={Tag} {...innerProps} {...otherProps} />;
}

const skeletonTypes = ['button', 'address', 'text', 'image', 'image-fill'];

Skeleton.propTypes = {
  type: PropTypes.oneOf(skeletonTypes),
};

export function SkeletonLoader({
  tag: Tag,
  type = 'text',
  isLoading = true,
  children,
  fallback,
  ...otherProps
}) {
  const context = useLoadingContext();

  let errors;
  if (context) {
    errors = context.errors;
    isLoading = context.isLoading;
  }

  // If loading, short circuit here.
  if (isLoading) {
    return <Skeleton tag={Tag} type={type} {...otherProps} />;
  }

  if (errors) {
    if (typeof fallback === 'function') {
      return fallback();
    }
    return <>#Error</>;
  }

  // If children is a function, invoke it in order to pass the
  // resulting elements to our parent in createElement below.
  try {
    if (typeof children === 'function') {
      children = children();
    }
  } catch (e) {
    console.log(
      `Error rendering children. 
       If the child references a value that hasn't been loaded yet, this may be the cause.`,
      e
    );
    return null;
  }

  const element =
    Tag == null
      ? React.createElement(React.Fragment, {}, children)
      : React.createElement(Tag, { ...otherProps }, children);

  return element;
}

SkeletonLoader.propTypes = {
  type: PropTypes.oneOf(skeletonTypes),
  isLoading: PropTypes.bool,
  tag: PropTypes.node,
};
