import { useEffect, useReducer, useCallback } from 'react';
import { useDebounce } from '@/hooks';

const defaultOptions = {
  debounceDelay: 500,
};

// Wrap reducers to support __reset action type.
function wrapReducer(innerReducer) {
  return (state, action) => {
    if (action.type === '__reset') {
      return action.payload;
    }
    return innerReducer(state, action);
  };
}

/**
 * useSessionStorage
 * This hook differs from usePersistedState because this hook doesn't contain the source of truth, but depends on an external state.
 * @param {String} key The key to use when persisting the state.
 * @param {String} reducer The reducer function.
 * @param {Object} initialState The initial state.
 * @param {Object} options Optional options.
 */
export default function useSessionStorage(key, reducer, initialState, options) {
  if (typeof reducer !== 'function') {
    throw new Error(
      'The `reducer` parameter must be a function of signature `(state, action) => newState`'
    );
  }

  options = Object.assign(defaultOptions, options);
  const { debounceDelay } = options;

  // Create the state and initialize it.
  const [state, dispatch] = useReducer(
    wrapReducer(reducer),
    initialState,
    () => {
      const value = sessionStorage.getItem(key);
      if (value != null) {
        return JSON.parse(value);
      }
      return initialState;
    }
  );

  // Use a debouncer so we can limit the amount of times we
  // write to session state when values are changed in quick
  // succession.
  const debouncedState = useDebounce(state, debounceDelay);

  // Capture any changes and serialize them to session storage.
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(debouncedState));
  }, [key, debouncedState]);

  // Reset the state by writing to the session storage immediately.
  const reset = useCallback(
    (state) => {
      state = state || initialState;
      if (state == null) sessionStorage.removeItem(key);
      else sessionStorage.setItem(key, JSON.stringify(state));
    },
    [initialState, key]
  );

  return [state, dispatch, reset];
}
