import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hScrollToRef from '../services/hScrollToRef';
import { Badge, Fade } from 'reactstrap';

import styles from './Tabs.module.scss';

export default function Tabs(props) {
  function handleSelected(item) {
    if (props.onSelected) {
      props.onSelected(item);
    }
  }

  const classes = classNames(styles['tab-container'], props.className);

  return (
    <div className={classes}>
      {props.items.map((item) => (
        <TabItem
          key={item.key}
          item={item}
          active={item.key === props.selected}
          onSelected={handleSelected}
        />
      ))}
    </div>
  );
}

const itemShape = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

Tabs.propTypes = {
  items: PropTypes.arrayOf(itemShape),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelected: PropTypes.func,
};

function createTabItem(key, text, badge) {
  return { key, text, badge };
}

Tabs.createTabItem = createTabItem;

//
// TabItem
//

function TabItem(props) {
  const anchorRef = useRef(null);

  function handleClick() {
    hScrollToRef(anchorRef.current);
    if (props.onSelected) {
      props.onSelected(props.item);
    }
  }

  let classes = ['position-relative', styles['tab-item']];
  if (props.active) classes.push(styles['active']);

  const hasBadge = !!props.item.badge;

  return (
    <div className={classNames(classes)} ref={anchorRef}>
      <Fade in={hasBadge}>
        {hasBadge && (
          <Badge color="secondary" pill className={styles['badge']}>
            {props.item.badge}
          </Badge>
        )}
      </Fade>
      <button type="button" className="stretched-link" onClick={handleClick}>
        {props.item.text}
      </button>
    </div>
  );
}

TabItem.propTypes = {
  item: itemShape.isRequired,
  active: PropTypes.bool,
  onSelected: PropTypes.func,
};
