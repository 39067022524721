import React from 'react';
import * as Sentry from '@sentry/react';

import { Error } from '@/components';

function ErrorDetails() {
  return (
    <Error>
      {/* <small>
        Please wait a few moments and{' '}
        <Button color="link" className="btn-inline">
          try again
        </Button>
        .
      </small> */}
    </Error>
  );
}

const NullComponent = ({ children }) => children;

export default Sentry.withErrorBoundary(NullComponent, {
  fallback: (props) => <ErrorDetails {...props} />,
});
