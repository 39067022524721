import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import merge from 'merge';

import { reduceStatus } from '@/services';

// Create the loading context.
const LoadingContext = createContext();
LoadingContext.displayName = 'LoadingContext';
export default LoadingContext;

// Create a Provider that sets up the state.
export function LoadingProvider({ statuses, isLoading, error, children }) {
  const [loadingState, setLoadingState] = useState({
    error,
    isLoading: isLoading ?? false,
    setErrors: setError,
    setIsLoading,
  });

  if (Array.isArray(statuses)) {
    var reduced = reduceStatus(statuses);
    setLoadingState((state) => merge(true, state, reduced));
  }

  function setIsLoading(value) {
    setLoadingState((state) =>
      merge(true, state, { isLoading: isLoading || value })
    );
  }

  function setError(error) {
    setLoadingState((state) => merge(true, state, { error }));
  }

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setError(error);
  }, [error]);

  return (
    <LoadingContext.Provider value={loadingState}>
      {typeof children === 'function' ? children(loadingState) : children}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
};

export function useLoadingContext() {
  const context = useContext(LoadingContext);

  if (context == null) {
    return null;
  }

  return {
    ...context,
  };
}
