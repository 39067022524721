/**
 * Recursively converts the properties of an object to an array of paths.
 * @param {Object} obj The object to convert to paths.
 * @param {String} pathPrefix The initial prefix for the path (usually only used during recursion).
 * @param {Array} paths An array of paths.
 */
export function objectToPaths(obj, pathPrefix = '', paths = []) {
  for (var prop in obj) {
    if (Array.isArray(obj)) {
      objectToPaths(
        obj[prop],
        pathPrefix
          ? `${pathPrefix.substr(0, pathPrefix.length - 1)}[${prop}].`
          : `${pathPrefix}[${prop}].`,
        paths
      );
    } else if (typeof obj[prop] === 'object' && obj[prop] !== undefined) {
      if (obj[prop] === null || Object.keys(obj[prop]).length === 0) {
        paths.push(`${pathPrefix}${prop}`);
      } else {
        objectToPaths(obj[prop], `${pathPrefix}${prop}.`, paths);
      }
    } else {
      paths.push(pathPrefix + prop);
    }
  }
  return paths;
}

/**
 * Maps a service key-value object to an array.
 * e.g. { 23: 1 } -> [{ serviceID: 23, quantity: 1 }]
 * @param {Object} serviceKeyValueObj
 */
export function mapServiceObjToArray(serviceKeyValueObj) {
  if (serviceKeyValueObj == null) return [];
  return Object.keys(serviceKeyValueObj)
    .filter((id) => serviceKeyValueObj[id].quantity > 0)
    .map((id) => ({
      serviceID: Number(id),
      ...serviceKeyValueObj[id],
    }));
}
