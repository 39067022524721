import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import { ErrorMessage } from '@/components/form';
import useFormikFieldFast from '@/hooks/form/useFormikFieldFast';

export default function CheckInput({
  id,
  name,
  touched,
  noError,
  onChange,
  ...otherProps
}) {
  const [field, meta, helpers] = useFormikFieldFast(name);

  // When using useField, the helper functions are not memoized
  // and thus change on every render. Because of this, don't add
  // them to the dependencies.
  // useEffect(() => {
  //   helpers.setTouched((touched) => touched || false);
  // });

  useEffect(() => {
    if (touched === true) {
      helpers.setTouched(true);
    }
  }, []);

  if (field == null) return null;
  const { onChange: _, ...otherField } = field;

  const handleChange = (e) => {
    field.onChange(e);

    if (onChange) {
      onChange(e.target.checked);
    }
  };

  const isError = meta.touched && meta.error != null;
  const resolvedID = id || name;
  const fieldValue = field.value || false;

  return (
    <>
      <CustomInput
        type="checkbox"
        id={resolvedID}
        checked={fieldValue}
        invalid={isError}
        onChange={handleChange}
        {...otherProps}
        {...otherField}
      />
      {!noError && <ErrorMessage name={name}></ErrorMessage>}
    </>
  );
}

CheckInput.propTypes = {
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  // Don't render an error message along with the checkbox
  noError: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
