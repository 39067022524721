import React, { useState } from 'react';
import { recursive as merge } from 'merge';
import { useParams } from 'react-router-dom';

import { Schema } from './provider';
import { Formik, Form } from 'formik';
import { httpRequest } from '@/services';
import { useTitle, useLoad, useToggle } from '@/hooks';
import { TextInput, SelectInput } from '@/components/form';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import {
  BodyContainer,
  Hero,
  Button,
  Loader,
  Alert,
  Spinner,
  FormSection,
  Skeleton,
} from '@/components';

import { makePair } from '@/components/form/SelectInput';
export default function ContactUs({ locationID }) {
  useTitle('Connect with Us');
  const [events, setEvents] = useState([]);
  const [locationName, setLocationName] = useState();
  const [showMessageSent, toggleShowMessageSent] = useToggle(false);

  const { status: fetchStatus } = useLoad(() => {
    return Promise.all([
      httpRequest.get(`api/location/${locationID}`).then(({ locationName }) => {
        setLocationName(locationName);
      }),
      httpRequest
        .get(`api/location/${locationID}/events/current`)
        .then(setEvents),
    ]);
  });

  const { load: submit, status: submitStatus } = useLoad(
    async ({ formState, resetForm }) => {
      await httpRequest.post('api/customer-lead', {
        payload: {
          lead: {
            contact: formState.contact,
            event: Object.assign({}, formState.event, {
              eventID: Number(formState.event.eventID),
            }),
          },
          methodOfContact: formState.methodOfContact,
          message: formState.message,
        },
      });
      resetForm();
      toggleShowMessageSent();
    },
    { autoLoad: false }
  );

  function sendMessage(formState, helpers) {
    const { resetForm } = helpers;
    submit({ formState, resetForm });
  }

  const defaultState = merge(Schema.defaultState, {
    event: { locationID: Number(locationID) },
  });

  return (
    <Formik
      onSubmit={sendMessage}
      initialValues={defaultState}
      validationSchema={Schema.validation}>
      <Form>
        <Loader {...fetchStatus}>
          <Hero size="sm" className="shadow-sm px-4">
            <h2 className="text-medium">Connect with Us</h2>
            <p className="mx-sm-auto w-sm-75 w-md-50">
              Tell us about your event needs, and a dedicated Cox Business
              Representative will help you find the right solution.
            </p>
          </Hero>
          <BodyContainer>
            <FormSection
              title="Event Information"
              titleClass="bg-gray-700 text-white">
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Location</Label>
                    <div>
                      {fetchStatus.isLoading ? (
                        <Skeleton type="text" />
                      ) : (
                        locationName
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Event</Label>
                    <SelectInput
                      isNumber
                      name="event.eventID"
                      placeholder="Select an upcoming event"
                      options={events.map(({ eventID, eventName }) =>
                        makePair(eventID, eventName)
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormSection>
            <FormSection
              title="Contact Information"
              titleClass="bg-gray-700 text-white">
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Company Name</Label>
                    <TextInput
                      name="contact.companyName"
                      placeholder="Company Name"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={9}>
                  <FormGroup>
                    <Label>First Name</Label>
                    <TextInput
                      name="contact.firstName"
                      placeholder="First Name"
                    />
                  </FormGroup>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <Label>Last Name</Label>
                    <TextInput
                      name="contact.lastName"
                      placeholder="Last Name"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={9}>
                  <FormGroup>
                    <Label>Phone</Label>
                    <TextInput
                      type="tel"
                      name="contact.phone"
                      placeholder="000-000-0000"
                    />
                  </FormGroup>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <Label>Email</Label>
                    <TextInput
                      type="email"
                      name="contact.email"
                      placeholder="name@address.com"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form className="mt-5">
                <Col>
                  <FormGroup>
                    <Label>Preferred Method of Contact</Label>
                    <SelectInput
                      name="methodOfContact"
                      options={[makePair('Phone'), makePair('Email')]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Tell Us About Your Needs</Label>
                    <TextInput
                      type="textarea"
                      name="message"
                      style={{ height: 150 }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormSection>
            {submitStatus.isError && (
              <Alert type="warning" vertical>
                We're sorry, but your message could not be sent right now.
                Please note that we have been made aware of the issue and are
                working to correct it. Please contact a Customer Representative
                for further assistance.
              </Alert>
            )}
            <Modal isOpen={showMessageSent} centered={true}>
              <ModalHeader>
                <i className="icon-check" /> Message sent!
              </ModalHeader>
              <ModalBody>
                Your message was sent! Please expect a reply within 24-48
                business hours.
                <Button
                  block
                  color="primary"
                  className="mt-4"
                  onClick={toggleShowMessageSent}>
                  Dismiss
                </Button>
              </ModalBody>
            </Modal>
            <Row className="mt-5">
              <Col className="d-flex flex-wrap">
                <Button
                  type="submit"
                  color="primary"
                  className="btn-form"
                  isLoading={submitStatus.isLoading}
                  loader={
                    <>
                      <Spinner size="sm" color="light" /> Sending message...
                    </>
                  }>
                  Send message
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Loader>
      </Form>
    </Formik>
  );
}
